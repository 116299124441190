import { sanitize } from "@/utils/components";

export default {
  install (Vue: any) {
    Vue.directive('sanitizeHtmlCustom', (el: any, binding: any) => {
      console.log(el, binding);
      const value = binding.value;
      el.innerHTML = sanitize(value)
    })
    // Vue.directive('other-directive', myOtherDirective)
  }
}


