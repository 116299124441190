import { Component, Prop, Vue } from 'vue-property-decorator';
import findIndex from 'lodash.findindex';
import { DeepReadonly } from 'ts-essentials';
import { isIncludedRoute, isSameRoute } from '@/utils/routing';
import { UINavigationItem } from '@/types';

@Component
export default class NavigationMixin extends Vue {
  @Prop({ required: true, type: Array })
  public readonly links!: UINavigationItem[];
  @Prop({ required: false, type: Object, default: () => ({}) })
  public readonly containerProps!: DeepReadonly<Record<string, unknown>>;
  @Prop({ required: false })
  public readonly activeLink!: UINavigationItem;
  @Prop(Boolean)
  public readonly noRouter!: boolean;

  public get derivedLinks() {
    return this.links;
  }

  public get key(): string {
    return JSON.stringify(this.derivedLinks);
  }

  public isCurrentRoute(link: UINavigationItem) {
    if (link) {
      if (link.disabled) {
        return false;
      }
      if (link.exact) {
        return isSameRoute(this.$route, this.$router.resolve(link.route).resolved);
      }
      return isIncludedRoute(this.$route, this.$router.resolve(link.route).resolved);
    }
    return false;
  }

  public isExactCurrentRoute(link: UINavigationItem) {
    if (link.disabled) {
      return false;
    }
    return isSameRoute(this.$route, this.$router.resolve(link.route).resolved);
  }

  public get activeIndex() {
    if (this.noRouter) {
      return findIndex<UINavigationItem>(
        this.derivedLinks,
        link => link.key === this.activeLink.key,
      );
    } else {
      return findIndex<UINavigationItem>(this.derivedLinks, link => this.isCurrentRoute(link));
    }
  }

  public get activeItem() {
    return this.derivedLinks.find((item) => {
      return this.isActiveItem(item);
    });
  }

  public isActiveItem(link: UINavigationItem) {
    if (this.noRouter) {
      return link.key === this.activeLink.key;
    }
    return this.isCurrentRoute(link);
  }

  public handleClick(link: UINavigationItem) {
    if (this.noRouter) {
      this.$emit('update:activeLink', link);
    } else {
      if (!this.isExactCurrentRoute(link)) {
        if (link.replace) {
          this.$router.replace(link.route);
        } else {
          this.$router.push(link.route);
        }
      }
    }
  }

  public getProps(link: UINavigationItem) {
    if (link.props) {
      return link.props;
    }

    return {};
  }

  public classes(link: UINavigationItem) {
    const active = this.isActiveItem(link);
    return {
      active,
      inactive: !active,
      isDisabled: link.disabled,
    };
  }
}
