
import { Vue, Component } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import Badge from '@/components/Badge.vue';
import { NotificationsModule } from '@/store/modules/notifications.module';
import { NotificationsPayload } from '@/types/notifications.types';
import { RouteNames } from '@/constants';

@Component({
  components: {
    Badge,
  },
})
export default class NotificationTab extends Vue {
  get notificationRoute(): RawLocation {
    let type = 'notifications';
    const mod = NotificationsModule.notifications;
    if (mod.unreadNotificationCount === 0 && mod.unreadRequestCount > 0) {
      type = 'requests';
    }
    return {
      name: RouteNames.NotificationTable,
      params: {
        type: type,
      },
    };
  }

  public get showCount() {
    return this.count > 0;
  }

  public get count() {
    const total =
      NotificationsModule.notifications.unreadNotificationCount +
      NotificationsModule.notifications.unreadRequestCount;
    return total;
  }

  created() {
    if (NotificationsModule.notifications.unreadNotificationCount === -1) {
      const payload: NotificationsPayload = {
        requestsOnly: false,
        query: null,
        results: 10,
        page: 1,
      };
      NotificationsModule.getNotifications(payload);
    }
  }
}
