import { css, customElement, html, LitElement, property } from 'lit-element';
import { TemplateResult } from 'lit-html';

@customElement('docs-component-previewer')
export default class ComponentPreviewer extends LitElement {
  @property({ type: Array })
  protected tabs: string[] = [];

  @property({ type: String })
  public active: string = this.tabs[0] || '';

  public firstUpdated() {
    this.active = this.tabs[0] || '';
  }

  public static styles = css`
    .hide {
      display: none;
    }
    .nav-underline {
      border-bottom: 1px solid #efefef;
      max-width: 699px;
      margin-bottom: 15px;
    }
    .tab-wrapper {
      width: 499px;
    }
  `;

  protected updateTab(tab: string) {
    this.active = tab;
  }

  protected render(): TemplateResult {
    return html`
      <div class="nav-underline">
        <div class="tab-wrapper">
          <gsk-tab-bar>
            ${this.tabs.map(
              tab =>
                html`
                  <gsk-tab @click="${() => this.updateTab(tab)}">${tab}</gsk-tab>
                `,
            )}
          </gsk-tab-bar>
        </div>
      </div>
      <div style="max-width: 699px">
        ${this.tabs.map(
          tab =>
            html`
              <slot class="${tab === this.active ? '' : 'hide'}" name="${tab}"></slot>
            `,
        )}
      </div>
    `;
  }
}
