import { css, customElement, html, LitElement, property } from 'lit-element';
import { TemplateResult } from 'lit-html';

@customElement('docs-token-download')
export default class TokenDownload extends LitElement {
  protected get exampleCode() {
    return `registry=https://pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/registry/
always-auth=true
; Treat this auth token like a password. Do not share it with anyone, including Microsoft support.
; begin auth token
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/registry/:username=DevOps-Artifacts
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/registry/:_password=${this.base64Token}
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/registry/:email=npm requires email to be set but doesn't use the value
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/:username=DevOps-Artifacts
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/:_password=${this.base64Token}
//pkgs.dev.azure.com/DevOps-Artifacts/_packaging/gsk-artifacts/npm/:email=npm requires email to be set but doesn't use the value
; end auth token`;
  }

  @property({ type: String })
  protected token = '';

  protected get base64Token() {
    return this.token ? btoa(this.token) : '[BASE64_ENCODED_PERSONAL_ACCESS_TOKEN]';
  }

  public static styles = css`
    .hide {
      display: none;
    }
    .text-wrapper {
      display: inline-flex;
    }
    .text-field {
      width: 699px;
    }
    .button-wrapper {
      align-self: center;
      margin-top: 25px;
      margin-left: 20px;
    }
    .code-wrapper {
      font-family: Source Code Pro, monospace;
      font-size: 14px;
      line-height: 1.43;
      color: #544f40;
      overflow-x: scroll;
    }
    .code {
      max-width: 699px;
      min-height: 66px;
      border-radius: 4px;
      background-color: #efefed;
      padding: 20px;
      padding-right: 64px;
    }
    .section-wrapper {
      margin-top: 34px;
    }
    .section-title {
      font-weight: bold;
      margin-bottom: 19px;
    }
  `;

  protected render(): TemplateResult {
    return html`
      <div>
        <div class="text-wrapper">
          <div>
            <gsk-textfield
              class="text-field"
              label="Access Token"
              placeholder="Enter your access token"
              @input="${(e: Event) => (this.token = (e.target as HTMLInputElement).value)}"
            ></gsk-textfield>
          </div>
        </div>
        <div class="section-wrapper">
          <p class="section-title">What does my file look like?</p>
          <docs-copy-code>
            <pre slot="code" style="padding: 12px">${this.exampleCode}</pre>
          </docs-copy-code>
        </div>
      </div>
    `;
  }
}
