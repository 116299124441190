import timezoneAbbrevations from './timezone_abbrevations.json';

export enum RouteNames {
  Admin = 'admin-dashboard',
  AdminFeatureFlags = 'feature-flags',
  AdminListingTypes = 'listing-types',
  AdminApplicationTeamRoles = 'application-team-roles',
  AdminListingTypeBusinessUnitApprovers = 'listing-type-business-unit-approvers',
  AdminServiceProjectMovement = 'service-project-movement',
  AdminServiceManagement = 'service-management',
  AdminTOLockingStatuses = 'admin-to-locking-statuses',
  AdminRegistrationFormTesting = 'admin-registration-form-testing',
  KongMonitoringPanel = 'Kong Monitoring Panel',
  OrchestratorMonitor = 'orchestrator-monitor',
  CloudPortal = 'cloud-portal',
  NewProject = 'projects-new',
  NewProjectForm = 'projects-new-form',
  NewRpaProject = 'projects-new-rpa',
  NewRpaProjectBot = 'projects-new-rpa-bot',
  CicdRequest = 'cicd-request',
  ProjectSettings = 'project-settings',
  RegisterApi = 'register-api',
  RegisterApiOptions = 'register-api-options',
  RegisterNewApi = 'register-new-api',
  RegisterApiService = 'register-api-service',
  RegisterApiRoute = 'register-api-route',
  RegisterApiVersion = 'register-api-version',
  RegisterApiNewVersion = 'register-api-new-version',
  RegisterApiEdit = 'register-api-edit',
  RegisterApiNewEdit = 'register-api-new-edit',
  PromoteSettings = 'promote-settings',
  ProjectTemplate = 'project-template',
  ProjectPermissions = 'project-permissions',
  ProjectsList = 'projects-list',
  ProjectDetails = 'project-details',
  ProjectConnected = 'project-connected',

  ProjectEnvDetails = 'project-env-API',
  ProjectCiCd = 'project-env-CICD',
  ProjectRpa = 'project-env-RPA',

  ProjectPromote = 'project-promote',
  ProjectPromoteNew = 'project-promote-new',
  PromoteRpaBot = 'promote-rpa-bot',
  EditRpaBot = 'edit-rpa-project',
  //ProjectCiCd = 'project-cicd',
  ProjectCiCdRepos = 'project-cicd-repos',
  Listings = 'catalog',
  ListingDetails = 'catalog-listing',
  ListingConnect = 'catalog-service-connect',
  ListingConnectCicd = 'catalog-service-connect-cicd',
  ListingType = 'catalog-type',
  ListingSection = 'catalog-listing-section',
  Create = 'create',
  NotFound = 'not-found',
  MyListings = 'my-listings',
  PublishListing = 'publish-listing',
  ListingPermissions = 'listing-permissions',
  MarkdownEditor = 'markdown',
  BotEnv = 'bot-env',
  BotConsole = 'bot-console',
  BotConsoleAccess = 'access-management',
  RpaAudit = 'rpa-audit',
  OpportunityAssessment = 'opportunity-assessment-details',
  LandingPage = 'opportunity-assessment-landing-page',
  BotType = 'bot-type',
  SwitchAccount = 'switch-account',
  MyTeams = 'my-teams',
  NewTeam = 'new-team',
  EditTeam = 'edit-team',
  TeamDetails = 'team-details',
  Notifications = 'notifications',
  NotificationDetails = 'notification-details',
  NotificationTable = 'notification-table',
  Om27 = 'om27',
  RpaOpsUnassignedBots = 'unassigned-bots',
  RpaOpsScheduledReporting = 'scheduled-reporting',
  RpaOpsScheduledReportingCreate = 'scheduled-reporting-create',
  RpaOpsScheduledReportingEdit = 'scheduled-reporting-edit',
  RpaOpsScheduledReportingView = 'scheduled-reporting-view',
  RpaOpsOpenTicket = 'rpa-open-ticket',
  RpaOpsBlockingTicket = 'rpa-blocking-ticket',
  RpaOpsSignOff = 'rpa-sign-off',
  RpaOpsSignOffHistory = 'rpa-sign-off-history',
  RpaOpsSignOffDetails = 'rpa-sign-off-details',
  RpaOpsResetData = 'rpa-reset-data',
  RpaOpsSearch = 'rpa-search',
  AuthCallback = 'authcallback',
  RpaOpsEditBot = 'rpa-edit-logical-bot',
  RpaOpsEditBotHistory = 'rpa-edit-logical-bot-history',
  CrossChargingReporting = 'Cross-charging reporting',

  MyTokens = 'my-tokens',
  NewToken = 'generate-token',
  EditToken = 'edit-token',
}

export enum Ping {
  Logout = 'https://federation-qa.gsk.com/idp/startSLO.ping',
}

export enum Events {
  ShowSnackbar = 'show-snackbar',
  OpenPopover = 'open-popover',
  ClosePopover = 'close-popover',
}

export enum Environments {
  Dev = 'Dev',
  Qa = 'QA',
  Prod = 'Prod',
}

export enum ChartDateFormats {
  DAY = 'YYYY-MM-DD',
  WEEK = 'w, YYYY',
  MONTH = 'MMM YYYY',
}

export const organisationAllowList = [
  'DevOps-Corp',
  'DevOps-CorpPlatforms',
  'DevOps-Cx',
  'DevOps-Cx-Data',
  'DevOps-PSCTech',
  'DevOps-RD',
  'DevOps-Rx',
  'DevOps-Vx',
  'GSK-CORP-DevOps',
];

export enum Statuses {
  Provisioning = 'Provisioning',
  Provisioned = 'Provisioned',
  Success = 'Success',
  PendingApproval = 'Pending Approval',
  Approved = 'Approved',
  ApprovedAndProvisioned = 'Approved And Provisioned',
  KeysReceived = 'Keys Received',
  Active = 'Active',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
  Failed = 'Failed',
  Draft = 'Draft',
  Published = 'Published',
  Deleting = 'Deleting',
}

export enum Roles {
  Owner = 1,
  TechnicalOwner = 2,
  Contributor = 3,
  RiskPartner = 4,
  TeamOwner = 8,
  TeamMember = 9,
  ListViewer = 5,
  ProjectViewer = 6,
  ProjectEditor = 7,
}

export enum RoleNames {
  Owner = 'Owner',
  TechnicalOwner = 'Technical Owner',
  Contributor = 'Contributor',
  RiskPartner = 'Risk Partner',
  TeamOwner = 'Team Owner',
  TeamMember = 'Team Member',
  ProjectViewer = 'Read Only',
  ProjectEditor = 'Owner',
  ListViewer = 'Listing Viewer',
}

export enum ListingTypes {
  API = 1,
  WebComponent = 2,
  MobileComponent = 3,
  RPA = 4,
  GithubRepo = 5,
}

export const teamRoleNamesById: Record<string, string> = {
  3: RoleNames.TeamOwner,
  4: RoleNames.TeamMember,
};
export const listPermissionsById: Record<string, string> = {
  1: RoleNames.Owner,
  5: RoleNames.ListViewer,
};

export const listingTypesById: Record<number, string> = {
  1: 'API',
  2: 'Web Component',
  3: 'Mobile Component',
  4: 'RPA',
  5: 'GitHub Repo',
};

export enum ListingSectionTemplateTypes {
  OpenApi = 'open-api',
  Markdown = 'markdown',
}

export const ListingSectionTemplateTypeIds = {
  [ListingSectionTemplateTypes.OpenApi]: 2,
  [ListingSectionTemplateTypes.Markdown]: 1,
};

// we dont' need an enum for this
export enum TextfieldInfo {
  nameLabel = 'Project Name',
  teamNameLabel = 'Team Name',
  namePlaceholder = 'What would you like to call your project?',
  teamNamePlaceholder = 'What would you like to call your team?',
  nameValidationMessage = 'Please enter a project name',
  teamValidationMessage = 'Please enter a team name',
  descriptionLabel = 'Description',
  descriptionPlaceholder = 'Provide a brief description of your project, you’ll be able to edit this later.',
  teamDescriptionPlaceholder = 'Provide a brief description of your team',
  descriptionValidationMessage = 'Please enter a short description of your project',
  teamDescriptionValidationMessage = 'Please enter a short description of your team',
  businessPurposeLabel = 'Business Purpose',
  businessPurposePlaceholder = 'What is the business purpose for promoting to production?',
  businessPurposeValidationMessage = 'Please enter a business purpose',
  searchPlaceholder = 'Search our product catalog',
  accessManagementSearchPlaceholder = 'Search for an Existing User (MudId or Account Id)',
  TeamRoleInfoHelp = 'Owners can edit the team, including adding and deleting users. ' +
    'Team members will only receive the permissions the team has been given',
  tokenDescriptionPlaceholder = '',
  tokenDescriptionValidationMessage = '',
}

export const mocksEnabled = process.env.VUE_APP_CLIENT_API_MOCKS === 'true';
export const kongApiKeyIdDefaultValue = 'apikey';

export const AUTH_SRC: Record<
  string,
  {
    mnemonic: string;
    readableName: string;
    shortName: string;
    apiRegistrationEnum: string;
    servicePropEnumVal: string;
  }
> = {
  AD_OAUTH: {
    mnemonic: 'AD_OAUTH',
    shortName: 'AD',
    readableName: 'Azure Active Directory (OAuth)',
    apiRegistrationEnum: 'AZUREAD_OAUTH2',
    servicePropEnumVal: '2',
  },
  PING_OAUTH: {
    mnemonic: 'PING_OAUTH',
    shortName: 'Ping',
    readableName: 'PingFederate (OAuth)',
    apiRegistrationEnum: 'PING_OAUTH2',
    servicePropEnumVal: '1',
  },
  API_KEY: {
    mnemonic: 'API_KEY',
    shortName: 'Basic',
    readableName: 'API Key',
    apiRegistrationEnum: 'APIKEY',
    servicePropEnumVal: '0',
  },
  GCP_OAUTH: {
    mnemonic: 'GCP_OAUTH',
    shortName: 'GCP',
    readableName: 'Google Cloud Platform (OAuth)',
    apiRegistrationEnum: 'GCP_OAUTH2',
    servicePropEnumVal: '3',
  },
};

export const Platforms: Record<
  string,
  {
    type: string;
    platformVal: string;
    readableName: string;
  }
> = {
  GCP: {
    type: 'GCP',
    readableName: 'Google Cloud Platform',
    platformVal: 'GCP_OAUTH',
  },
  AZURE: {
    type: 'Azure',
    readableName: 'Azure Cloud',
    platformVal: 'AD_OAUTH',
  },
};

export const LlmIgnoreChars = {
  TILDE: /\/?~+/g,
  AI_PATH: '/openai/deployments/(?<azure_deployment>[^#?/]+)(?<operation_path>[^#?]+)$',
}

export const TZ_ABBREVATIONS: Record<string, string> = Object.freeze(timezoneAbbrevations);
