
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Choice } from '@gsk-tech/gsk-people-picker/gsk-people-picker-base';
import { BaseUser } from '@/types/users.types';
import { UserModule } from '@/store/modules/user.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

interface PeoplePickerEvent extends CustomEvent {
  detail: {
    value: string;
    selectedChoices: Choice[];
  };
}
@Component({
  inheritAttrs: false,
})
export default class GPeoplePicker extends Vue {
  @Prop(Array) readonly value!: BaseUser[];
  @Prop({ type: Boolean, default: true }) readonly valid!: boolean; //default valid value is set to true
  @Prop({ type: Boolean, default: false }) readonly singleSelect!: boolean;

  get apiUrl(): string {
    return ProcessEnvsModule.processEnvs.searchBaseUrl;
  }

  get token(): string {
    return UserModule.user.accessToken;
  }

  get derivedUsers(): Choice[] {
    return this.value.map((user): Choice => {
      return {
        value: user.mudId,
        label: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
        entry: {
          source_key: '',
          title: '',
          link: '',
          id: '',
          reference: '',
          updated: '',
          summary: '',
          relevance: '',
          startIndex: '',
          content: {
            EMAIL: user.extra?.EMAIL ?? user.email,
            FIRST_NAME: user.extra?.FIRST_NAME ?? user.firstName ?? '',
            LAST_NAME: user.extra?.LAST_NAME ?? user.lastName ?? '',
            MUDID: user.mudId,
            PICTUREURL: user.extra?.PICTUREURL ?? '',
            JOBTITLE: user.extra?.JOBTITLE ?? '',
            LOCATION: user.extra?.LOCATION ?? '',
          },
        },
      };
    });
  }
  get usersValue() {
    return this.value.map((user: BaseUser) => user.mudId).join(',');
  }

  get eventHandlers() {
    return {
      change: this.onChange,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }

  onChange(e: PeoplePickerEvent) {
    const sc = e.detail.selectedChoices;
    if (!sc) {
      return;
    }
    const people = sc.map((person: Choice): BaseUser => {
      return {
        firstName: person.entry.content.FIRST_NAME,
        lastName: person.entry.content.LAST_NAME,
        fullName: `${person.entry.content.FIRST_NAME} ${person.entry.content.LAST_NAME}`,
        mudId: person.entry.content.MUDID,
        email: person.entry.content.EMAIL,
        extra: person.entry.content,
      };
    });
    this.$emit('input', people);
    this.$emit('change', people);
    return people;
  }
}
