import { DeepPartial } from 'ts-essentials';

export type First<T extends unknown[]> = T[0];

export interface TreeNode<T extends Record<string, any> = Record<string, any>> {
  id: number;
  text: string;
  data: T;
  children: TreeNode<T>[];
  state: TreeNodeState;
}

// the source data for a node doesn't need to be the full type
export type TreeNodeSource<T extends Record<string, any> = Record<string, any>> = Pick<
  TreeNode<T>,
  'text'
> & { children: TreeNodeSource<T>[] } & Omit<DeepPartial<TreeNode<T>>, 'children'>;

export interface TreeNodeState {
  selected: boolean;
  selectable: boolean;
  checked: boolean;
  expanded: boolean;
  disabled: boolean;
  visible: boolean;
  indeterminate: boolean;
  matched: boolean;
  editable: boolean;
  dragging: boolean;
  draggable: boolean;
  dropable: boolean;
}

export enum IconStatus {
  PROVISIONED = 'Provisioned',
  PROVISIONING = 'Provisioning',
  FAILED = 'Failed',
  SCANNED = 'Scanned',
  DELETING = 'Deleting',
  SCANNING = 'Scanning',
  REJECTED = 'Rejected',
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  PENDINGAPPROVAL = 'Pending Approval',
  INSECURE = 'Insecure'
}

export type IconStatusType = IconStatus.PROVISIONED 
| IconStatus.PROVISIONING
| IconStatus.REJECTED 
| IconStatus.FAILED 
| IconStatus.SCANNED 
| IconStatus.SCANNING
| IconStatus.DRAFT
| IconStatus.PUBLISHED
| IconStatus.PENDINGAPPROVAL
| IconStatus.INSECURE
