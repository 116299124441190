import { parseISO, isToday, format } from 'date-fns';
import { enGB } from 'date-fns/locale';

export function convertDateTime(dateTime: string, showTime = false) {
  const date = parseISO(dateTime);
  if (isToday(date)) {
    return format(date, 'h:mm aa');
  }
  if (showTime) {
    return format(date, 'd MMMM, yyyy h:mm aa', { locale: enGB });
  }

  return format(date, 'd MMMM, yyyy', { locale: enGB });
}

export function currentYear(): number {
  return new Date().getFullYear();
}
