
import Vue from 'vue';
import { Component, Provide } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user.module';
import CopyCode from '@/components/CopyCode.vue';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';

@Component
export default class EmptyLayout extends Vue {
  get appData() {
    return {
      user: Object.freeze(UserModule.user),
      components: {
        CopyCode,
        GPeoplePicker,
      },
    };
  }
  @Provide() components = {
    CopyCode,
    GPeoplePicker,
  };
}
