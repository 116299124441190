import { css, customElement, html, LitElement, property } from 'lit-element';
import { TemplateResult } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { PopoverBase } from '@gsk-tech/gsk-popover/gsk-popover-base';
import debounce from 'lodash/debounce';
import { analyticsClick } from '@/analytics';
import { logger } from '@/utils/logger';

@customElement('docs-user-feedback')
export default class UserFeedback extends LitElement {
  @property({ type: Number })
  public slides = 1;

  @property({ type: Number })
  protected active = 0;

  @property({ type: String, reflect: true })
  public section = '';

  @property({ type: String })
  public goodIconOff = 'thumbs_up';

  @property({ type: String })
  public badIconOff = 'thumbs_down';

  @property({ type: String })
  public goodIcon = 'check';

  @property({ type: String })
  public badIcon = 'check';

  @property({ type: String })
  private worked: boolean | null = null;

  public get arr(): number[] {
    return Array.from({ length: this.slides }).map((_, i) => i);
  }

  private scrollHandler = debounce(() => {
    if (this.popover?.isOpen) {
      this.popover?.setPosition();
    }
  }, 125);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private clickHandler = (e: any) => {
    const target: HTMLElement = e?.target;
    if (target && this.popover?.isOpen) {
      if (!target.closest('#popover') && !target.closest('slot')) {
        this.popover.close();
      }
    }
  };

  public firstUpdated() {
    window.addEventListener('scroll', this.scrollHandler, true);
    document.addEventListener('click', this.clickHandler);
  }

  public disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('scroll', this.scrollHandler);
    document.removeEventListener('click', this.clickHandler);
  }

  public static styles = css`
    :host {
      display: block;
    }
    .feedback-wrapper {
      display: inline-flex;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      margin-top: 9px;
    }
    .up-icon {
      margin: 0 15px;
    }
    .popover-size {
      width: 305px;
      min-height: 212px;
      border-radius: 4px;
      padding: 24px;
      display: flex;
      flex-direction: column;
    }
    .content-wrapper {
      flex: 1;
    }
    ::slotted(*) {
      font-size: 14px;
    }
    ::slotted(.title) {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
    }
    .dots-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }
    .hide {
      visibility: hidden;
      pointer-events: none;
      user-select: none;
    }
    .dot {
      height: 8px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
    }
    .dot-selected {
      background-color: #544f40;
      border-radius: 50%;
      display: inline-block;
      height: 8px;
      width: 8px;
      cursor: pointer;
    }
  `;

  public get popover(): PopoverBase | null {
    return this.shadowRoot?.querySelector('#popover') ?? null;
  }

  protected closePopover() {
    this.popover?.close();
  }

  protected onWorked() {
    logger.debug('onWorked', this.worked);
    if (!this.worked) {
      this.worked = true;
      analyticsClick({
        clickTarget: 'component-install-user-feedback-positive',
        section: this.section,
      });
    }
  }

  protected onNotWorked() {
    logger.debug('onNotWorked', this.worked);
    if (this.worked !== false) {
      this.worked = false;
      analyticsClick({
        clickTarget: 'component-install-user-feedback-negative',
        section: this.section,
      });
    }
    this.openPopover();
  }

  protected openPopover() {
    if (!this.popover?.isOpen) {
      this.active = 0;
      this.popover?.open();
    }
  }

  protected nextContent() {
    if (this.slides < 2) {
      return;
    }
    if (this.active === this.slides - 1) {
      this.active = 0;
    } else {
      this.active++;
    }
    this.updateComplete.then(() => this.scrollHandler());
  }

  protected updateContent(num: number) {
    this.active = num;
  }

  protected getIcon(type: 'good' | 'bad') {
    const workedStr = () => this.worked ? 'true' : 'false';
    const worked: 'null' | 'true' | 'false' =
      this.worked === null ? 'null' : workedStr();
    const icons = {
      good: {
        true: this.goodIcon,
        false: this.goodIconOff,
        null: this.goodIconOff,
      },
      bad: {
        false: this.badIcon,
        true: this.badIconOff,
        null: this.badIconOff,
      },
    };
    return icons[type][worked];
  }

  protected render(): TemplateResult {
    return html`
      <div class="feedback-wrapper">
        <span class="title">Did this work?</span>
        <gsk-icon-button
          style="margin: 0 15px"
          icon="${this.getIcon('good')}"
          @click="${() => this.onWorked()}"
        ></gsk-icon-button>
        <gsk-icon-button
          id="negative"
          icon="${this.getIcon('bad')}"
          @click="${() => this.onNotWorked()}"
        ></gsk-icon-button>
        <gsk-popover id="popover" for="negative" position="auto">
          <div class="popover-size">
            <div class="content-wrapper">
              ${this.arr.map(num => {
                return html`
                  <slot name="${num}" style="${num === this.active ? '' : 'display: none'}"></slot>
                `;
              })}
            </div>
            <div class="dots-wrapper">
              <div style="align-self: center">
                ${this.arr.map(num => {
                  return html`
                    <span
                      class="${num === this.active ? 'dot-selected' : 'dot'}"
                      @click="${() => this.updateContent(num)}"
                    ></span>
                  `;
                })}
              </div>
              <gsk-button
                class="${classMap({ hide: this.slides < 2 })}"
                @click="${() => this.nextContent()}"
              >
                Next
              </gsk-button>
            </div>
          </div>
        </gsk-popover>
      </div>
    `;
  }
}
