import Router, { RawLocation } from 'vue-router';
import store from '@/store';
import { logger } from '@/utils/logger';

declare module 'vue-router/types/router' {
  interface VueRouter {
    safeBack(fallback: RawLocation): void;
  }
}

Router.prototype.safeBack = function safeBack(fallback: RawLocation): void {
  const { from } = store.state.route;
  if (from && from.name) {
    this.back();
  } else {
    this.push(fallback).catch(e => logger.warn(e));
  }
};
