export interface FeatureFlag {
  mnemonic: FeatureFlagMnemonics;
  featureFlagId: number;
  featureFlagName: string;
  featureFlagDescription: string;
  featureFlagEnabled: boolean;
  sequenceNumber: number;
  featureEnabledTeams: { teamId: number; teamName: string }[];
}

export enum FeatureFlagMnemonics {
  REGISTRATION = 'REGISTRATION',
  REGISTRATIONNEWFIELDS = 'REGISTRATIONNEWFIELDS',
  CLOUDPORTAL = 'CLOUDPORTAL',
  HEALTHCHECK = 'HEALTHCHECK',
  NOTIFICATIONS = 'NOTIFICATIONS',
  OPPASS = 'OPPASS',
  BOTPROMOTION = 'BOTPROMOTION',
  KONGENABLED = 'KONGENABLED',
  BOTPRODPROMOTION = 'BOTPRODPROMOTION',
  RPAOPS = 'RPAOPS',
  AZUREAD = 'AZUREAD',
  BOTCATALOG = 'BOTCATALOG',
  RPAOPSADDVERIFICATION = 'RPAOPSADDVERIFICATION',
  CICD = 'CICD',
  PING = 'PING',
  A360 = 'A360',
  TRYIT = 'TRYIT',
  DYNAMICREGISTRATION = 'DYNAMICREGISTRATION',
  CIIDPERSERVICE = 'CIIDPERSERVICE',
  APISECURITYSCAN = 'APISECURITYSCAN',
  CUSTDEV = 'CUSTDEV',
  CUSTQA = 'CUSTQA',
  CUSTPROD = 'CUSTPROD',
  GCP = 'GCP',
  PLATFORM = 'PLATFORM',
  OPENAPISPEC = 'OPENAPISPEC',
  BUDGETINGSOURCE = 'BUDGETINGSOURCE',
  PROMETHEUSANALYTICSCHART = 'PROMETHEUSANALYTICSCHART',
  KONGCIRCUITBRAKE='KONGCIRCUITBRAKE',
  PROMOTETOPRODNOTIFY='PROMOTETOPRODNOTIFY',
  PROMOTETOQANOTIFY='PROMOTETOQANOTIFY'
}

export type FeatureFlags = Record<FeatureFlagMnemonics, FeatureFlag>;
export type CreateFeatureFlagBody = Omit<FeatureFlag, 'featureFlagId' | 'mnemonic'> & {
  mnemonic: string;
};
export type UpdateFeatureFlagBody = Omit<FeatureFlag, 'mnemonic'> & {
  mnemonic: string;
};
