import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import { PaginatedFilterableRequest } from '@/types';
import URLs from '@/api/service/urls';
import * as Listings from '@/types/listings.types';
import { AdvSearchContainer, PaginatedListingsResponseBody } from '@/types/api.types';

function isValidListingId(id: number): boolean {
  return !isNaN(id) && id > -1;
}

export { Listings };
export type ListingAPIQuery = PaginatedFilterableRequest<{
  typeId?: number;
  isFeatured?: boolean;
  query?: string;
}>;
export type ListingsAPIResponseBody = PaginatedListingsResponseBody<Listings.Listings>;
export type ListingsSearchResponseBody = AdvSearchContainer<
  Listings.Listings<Listings.SavedListingModule & { listingId: number }>
>;

export interface ListingEnvironment {
  environmentName: string;
  environmentId: number;
  serviceId: number;
  versionId: number;
  authenticationType: string;
}

export type SearchFilter = Partial<{
  isFeatured: boolean; // currently a vue route param i think
  typeId: number; // vue route param
  query: string; // vue route query
  categoryId: number; // vue route query
  businessUnitId: number[]; // vue route query
  keyword: number[]; // vue route query
  userId: number; // vue route query ?? although if it's for private listings then won't need to be in URL and should come from the header instead of explicitly passed in this request
}>;

export interface SearchRequestDto {
  filter?: SearchFilter;
  resultsPerPage?: number; // hard code to 200 or so because no pagination on front end
  pageNumber?: number; // hardcode to 1 for same reason
}

export function getListings(query: ListingAPIQuery): AxiosPromise<ListingsAPIResponseBody> {
  return axios.post<ListingsAPIResponseBody>(URLs.Listings, query);
}

export function searchListings(query: SearchRequestDto): AxiosPromise<ListingsSearchResponseBody> {
  return axios.post(URLs.ListingsAdvancedSearch, query);
}

export function getListingDetails(
  listingId: number | string,
): AxiosPromise<Listings.SavedFullListing> {
  if (isValidListingId(listingId as number)) {
    return axios.get<Listings.SavedFullListing>(URLs.ListingDetails, {
      params: {
        listingId,
      },
    });
  } else {
    throw new Error();
  }
}

export function getListingVersionDetails(
  listingVersionId: number | string,
): AxiosPromise<Listings.SavedFullListing> {
  return axios.get<Listings.SavedFullListing>(URLs.ListingVersionDetails, {
    params: {
      listingVersionId,
    },
  });
}

export function getListingAvailableEnvironments(
  listingId: number | string,
): AxiosPromise<ListingEnvironment[]> {
  return axios.get(URLs.ListingEnvironments, {
    params: {
      listingId,
    },
  });
}

export function getAvailableRegistrations(): AxiosPromise<Listings.GetRegistrationsResponseBody> {
  return axios.get(URLs.GetRegistrations);
}

export function getConnectedProjects(listingId: number): AxiosPromise<Listings.ListingConnectedProjectsResponse> {
  return axios.get(URLs.ListingConnectedProjects, {
    params: { listingId },
  });
}
