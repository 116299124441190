import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { ListingType, ListingTypeCreateData } from '@/types/listing-types.types';

export function getAdminListingTypes(): AxiosPromise<ListingType[]> {
  return axios.get(URLs.AdminListingTypes, {});
}

export function createAdminListingType(data: ListingTypeCreateData): AxiosPromise<ListingType> {
  return axios.post(URLs.AdminListingTypes, data, {});
}

export function updateAdminListingType(data: ListingType): AxiosPromise<ListingType> {
  const { listingTypeId } = data;
  return axios.put(URLs.AdminListingTypesUpdate, data, {
    params: { id: listingTypeId },
  });
}

// Check on the arguments for this
export function deleteAdminListingType(listingTypeId: number): AxiosPromise<boolean> {
  return axios.delete(URLs.AdminListingTypesUpdate, {
    params: { id: listingTypeId },
  });
}
