import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { getHelpFileTree } from '@/api/help.api';
import { First, TreeNodeSource } from '@/types/util.types';
import { HELP_ROOT_PATH } from '@/constants/help.constants';

export interface HelpState {
  files: string[];
}

@Module({ name: 'help', namespaced: true, store, dynamic: true })
class Help extends VuexModule implements HelpState {
  /**
   * list of file paths for all files in repo
   *
   * @example [ "documentation/1. projects/Create a Project.md" ]
   */
  public files: string[] = [];

  /**
   * flat file list filtered to only include non-private folders/files within
   * the help content directory. removes empty directories.
   */
  public get helpFiles(): string[] {
    return (
      this.files
        .filter(path => path.startsWith(HELP_ROOT_PATH) && path.endsWith('.md'))
        // no paths starting with _, they are private by convention
        .filter(path => !/\/_/.test(path))
    );
  }

  /**
   * used to display a default path when the user doesn't navigate directly to one
   * since we don't have a documentation landing page at this time
   */
  public get defaultFilePath(): string {
    return '/' + (this.helpFiles.find(p => p.endsWith('.md')) || '');
  }

  /**
   * prepares the flat file list for the liquor-tree component
   */
  public get fileTree(): TreeNodeSource[] {
    const files = this.helpFiles.map(path => path.replace(HELP_ROOT_PATH, '/'));

    const root: TreeNodeSource[] = [];
    files.forEach(file => {
      let currentChildren = root;
      file
        .split('/')
        .filter(s => s)
        .forEach((part, i, arr) => {
          const node = currentChildren.find(node => node.text === part);
          const isLast = i === arr.length - 1;
          if (!node) {
            const newNode: TreeNodeSource = {
              text: part,
              state: {
                draggable: false,
              },
              children: [],
            };
            if (isLast) {
              newNode.text = part.replace(/\.md$/, '');
              newNode.data = {
                path: `/${HELP_ROOT_PATH}${file}`.replace('//', '/'),
              };
            }
            currentChildren.push(newNode);
            currentChildren = newNode.children;
          } else {
            currentChildren = node.children;
          }
        });
    });
    return root;
  }

  @Mutation
  private setFileTree(tree: string[]): void {
    this.files = tree;
  }

  @Action
  public async loadFileTree(payload: First<Parameters<typeof getHelpFileTree>>) {
    getHelpFileTree(payload).then(r => {
      this.setFileTree(r.data);
    });
  }
}

export const HelpModule = getModule(Help);
