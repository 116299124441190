import { extend, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import './api-gateway-validation';
import './array-validation';

setInteractionMode('eager');

/**
 * this is not good because we are registering rules that we are
 * registering rules that we might not use and making our bundle larger,
 * but it's more convenient
 */
for (const [rule, validation] of Object.entries(rules)) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message = (messages as any)[rule];
  extend(rule, {
    ...validation,
    message,
  });
}
