
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AuthCallback extends Vue {
  public state = '';
  public code = '';
  public message = '';
  public errorMessage = '';
  private enableDebugInfo = false;

  get stateFromLocalhost(): string {
    return window.localStorage.getItem('oauth_state') as string;
  }
  async delay(ms: number): Promise<void> {
    console.log('delaying', ms);
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async sendCodeToBFF(): Promise<void> {
    try {
      const payload = {
        code: this.code,
        provider: window.localStorage.getItem('oauth_provider'), // eslint-disable-line,
        callbackLink: window.localStorage.getItem('callbackLink'), // eslint-disable-line
      };

      console.log('payload', payload);

      this.message = '📡 Sending token to BFF ...';
      const response = await axios.post(`/api/auth/token`, payload);
      if (response.status === 201) {
        this.message = '✅ Token received by BFF, redirecting to homepage in 3 seconds...';
        window.location.href = localStorage.routePathBeforeGoToLogin || '/'; // eslint-disable-line
      } else {
        this.errorMessage = response?.data || '';
        console.log('Status', response.status);
        console.log('Body', response.data);
      }
    } catch (err) {
      this.errorMessage = err?.response?.data || '';
      console.log('Exception', err.message);
    }
  }

  created(): void {
    this.enableDebugInfo = localStorage['bffEnableDebugInfo'] === 'true';
    this.code = this.$router.currentRoute.query.code as string;
    this.state = this.$router.currentRoute.query.state as string;

    if (this.state && this.state === window.localStorage.getItem('oauth_state')) {
      // states match, send one time code (authorization code) to BFF
      console.log('✅  States match sending code to BFF');
      this.sendCodeToBFF();
    } else {
      this.errorMessage = "The state code is missing or doesn't match.";
    }
  }

  retryClicked(): void {
    this.$router.push('/');
  }
}
