import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  ApiRegistration,
  Approval,
  UpdateApprovalRequestBody,
  ApiRegistrationPayload,
} from '@/types/registration.types';
import { ApprovalType } from '@/types/notifications.types';
import { Projects } from '@/api/projects.api';

export function registerApi({
  projectId,
  data,
}: {
  projectId: number | string;
  data: ApiRegistrationPayload;
}): AxiosPromise<ApiRegistration> {
  return axios.post(URLs.ProjectRegistrations, data, {
    params: { projectId },
  }).catch(err => {
    throw new Error(err?.response?.data?.error || '');
  });
}

export function getApiRegistration(serviceId: string | number): AxiosPromise<ApiRegistration> {
  return axios.get(URLs.ProjectRegistration, {
    params: {
      serviceId,
    },
  });
}

export function validateProxyPath(proxyPath: string, proxyPathController?: AbortController): AxiosPromise<string[] | boolean> {
  return axios.get(URLs.ValidateProxyPath, {
    signal: proxyPathController?.signal,
    params: {
      path: proxyPath,
    },
  });
}

export function getAllProxyPath(platform: string): AxiosPromise<any> {
  return axios.get(URLs.AllProxyPath, {
    params: {
      platform
    }
  });
}

export function getAllProjectEnvironmentUpstreamUri(params: {
  projectEnvId: Projects.ProjectEnvironment['projectEnvironmentId'];
}): AxiosPromise<any> {
  return axios.get(URLs.AllProjectEnvironmentUpstreamUri, {
    params,
  });
}

export function isUpstreamUriUnique(body: any): AxiosPromise<any> {
  return axios.post(URLs.IsUpstreamUriUnique, body);
}

export function getListingForRegisterId(registrationId: number): AxiosPromise<any> {
  return axios.get(URLs.GetListingForRegisterId, {
    params: {
      registrationId
    }
  });
}

export function validateResourceName(
  resourceName: string,
  envMnemonic: string,
  resourceNameController?: AbortController,
  token?: any
): AxiosPromise<boolean> {
  return axios.get(URLs.ValidateResourceName, {
    cancelToken: token,
    signal: resourceNameController?.signal,
    params: {
      envMnemonic,
      path: resourceName,
    },
  });
}

export function updateApiRegistration({
  serviceId,
  data,
}: {
  serviceId: number | string;
  data: ApiRegistrationPayload;
}): AxiosPromise<ApiRegistration> {
  return axios.put(URLs.ProjectRegistration, data, {
    params: { serviceId },
  });
}

export function removeAPIRegistration(serviceId: number): AxiosPromise<unknown> {
  return axios.delete(URLs.ProjectRegistration, {
    params: { serviceId },
  });
}

export function addApiRegistrationVersion({
  serviceId,
  versionId,
  data,
}: {
  serviceId: number | string;
  versionId: number | string;
  data: ApiRegistrationPayload;
}): AxiosPromise<ApiRegistration> {
  return axios.post(URLs.ProjectRegistrationVersion, data, {
    params: { serviceId, versionId },
  });
}

export function getApproval(approvalId: string | number): AxiosPromise<Approval> {
  return axios.get(URLs.ProjectApproval, {
    params: {
      approvalId,
    },
  });
}

export function getAllApprovalRequestTypes(): AxiosPromise<unknown> {
  return axios.get(URLs.AllApprovalRequestTypes, {
    params: {
    },
  });
}

export function sendApproval(
  approvalRequestId: number | string,
  approval: UpdateApprovalRequestBody,
  approvalRequestTypeMnemonic: ApprovalType,
): AxiosPromise<Approval> {
  const url: Record<ApprovalType, URLs | null> = {
    APISYSTEMADMINAPPROVAL: URLs.ProjectProdApprovalResponse,
    APIOWNERAPPROVAL: URLs.APIOwnerApproverResponse,
    LISTINGTYPEBUSINESSUNITAPPROVAL: URLs.ListingTypeBusinessUnitApprovalResponse,
    RISKAPPROVAL: null,
    BOTPROMOTIONAPPROVAALTYPE: URLs.BotProdPromotionApprovalResponse,
    APIBUDGETAPPROVAL: URLs.APIOwnerApproverResponse,
  };
  const u = url[approvalRequestTypeMnemonic];
  if (u === null) {
    throw new Error(
      `Approval API not implemented for approval type: ${approvalRequestTypeMnemonic}`,
    );
  }
  return axios.post(u, approval, {
    params: {
      approvalRequestId,
    },
  });
}
