import { css, customElement, html, LitElement, property } from 'lit-element';
import { TemplateResult } from 'lit-html';

@customElement('docs-copy-code')
export default class DocumentationCopyCode extends LitElement {
  @property({ type: String })
  public code = '';

  @property({ type: String })
  protected copyIcon = 'copy';

  public static styles = css`
    ::slotted(pre) {
      margin: 0;
      font-size: 14px;
    }
    .code-wrapper {
      max-width: var(--content-max-width);
      width: 100%;
      border-radius: 4px;
      background-color: #efefed;
      display: inline-flex;
    }
    .code {
      font-family: 'Source Code Pro', monospace;
      font-size: 4px;
      line-height: 1.43;
      color: #544f40;
      overflow-x: scroll;
      flex: 1;
    }
  `;

  public copyCode() {
    const code = (this.querySelectorAll('[slot="code"]')[0] as HTMLElement).innerText.trim();
    navigator.clipboard
      .writeText(code)
      .then(() => {
        this.copyIcon = 'check';
        setTimeout(() => {
          this.copyIcon = 'copy';
        }, 800);
      })
      .catch(() => {
        this.copyIcon = 'close';
        setTimeout(() => {
          this.copyIcon = 'copy';
        }, 1600);
      });
  }

  protected renderIconButton(): TemplateResult | void {
    return html`
      <div>
        <gsk-icon-button
          style="padding: 10px;"
          icon="${this.copyIcon}"
          mini
          @click="${this.copyCode}"
        ></gsk-icon-button>
      </div>
    `;
  }

  protected render(): TemplateResult {
    return html`
      <div class="code-wrapper">
        <div class="code">
          <slot name="code"></slot>
        </div>
        ${this.renderIconButton()}
      </div>
    `;
  }
}
