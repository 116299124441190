import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import { DeepReadonly } from 'ts-essentials';
import store from '@/store';
import { AppEnum, AppEnums, AppEnumsBase, AppEnumsSelectOptions } from '@/types/enum.types';
import * as API from '@/api/enums.api';
import { SelectOption } from '@/components/form/form.types';
import { Environments } from '@/constants/index';

@Module({ name: 'enums', store, dynamic: true, namespaced: true })
class EnumTypesModule extends VuexModule {
  public hasEnums = false;
  public loading = false;
  private enumTypes: AppEnumsBase = {
    role: [],
    status: [],
    privilege: [],
    roleGroup: [],
    statusType: [],
    environment: [],
    environmentType: [],
    apiEnvironment: [],
    listingType: [],
    connectionType: [],
    registrationType: [],
    businessUnit: [],
    notificationFilter: [],
    botSystems: [],
  };
  private enumPromise: Promise<AppEnumsBase> = Promise.resolve(this.enumTypes);

  public get updateComplete(): Promise<void> {
    return this.enumPromise.then(() => void 0);
  }

  public get enums(): DeepReadonly<AppEnums> {
    return mapValues(this.enumTypes, enums => keyBy(enums, e => e.mnemonic));
  }

  public get enumsById(): DeepReadonly<AppEnums> {
    return mapValues(this.enumTypes, enums => keyBy(enums, e => e.id));
  }

  public get enumsSelectOptions(): AppEnumsSelectOptions {
    return mapValues(this.enumTypes, enums =>
      enums.map(e => {
        return {
          value: e.id.toString(),
          label: e.description,
          extra: e,
        };
      }),
    );
  }

  public get enumsLists(): DeepReadonly<AppEnumsBase> {
    return this.enumTypes;
  }

  public get enumSelectOptions(): SelectOption<AppEnumsBase>[] {
    return Object.values(Environments).map((env, key) => ({
      key: (key + 1).toString(),
      value: env.toLowerCase(),
      label: env === 'Prod' ? 'Production' : env,
    }));
  }

  public get businessUnitFormOptions(): SelectOption<AppEnum>[] {
    return this.enumsLists.businessUnit.map(bu => ({
      value: bu.id.toString(),
      label: bu.name,
      extra: bu,
    }));
  }

  @Mutation
  private setEnumType(enums: AppEnumsBase): void {
    this.hasEnums = true;
    this.enumTypes = enums;
    this.loading = false;
  }

  @Mutation
  private setEnumPromise(enums: Promise<AppEnumsBase>): void {
    this.loading = true;
    this.enumPromise = enums;
  }

  @Action({ rawError: true })
  public async getAllEnumTypes(): Promise<void> {
    const prom = API.getAllEnums();
    this.setEnumPromise(prom);
    const enumResponse = await prom;
    this.setEnumType(enumResponse);
  }
}

export const EnumsModule = getModule(EnumTypesModule);
