/**
 * other than Base, all of these match up to paths
 * defined in server controllers
 */
enum URLs {
  Base = '/api/proxy',
  Listings = '/listings/search',
  ListingsAdvancedSearch = '/listings/search-advanced',
  ListingDetails = '/listings/:listingId',
  ListingVersionDetails = '/listings/version/:listingVersionId',
  ListingEnvironments = '/listings/:listingId/environments',
  ListingOpenApiSpec = '/api/listings/:listingId/open-api-spec',
  ProjectsSearch = '/projects/search',
  Projects = '/projects',
  AllProjects = '/projects/admin',
  ManageThisProject = '/projects/:projectId/manage/:isEnabled',
  ConnectingServiceProjects = '/projects/connected-service/projects/list',
  Project = '/projects/:projectId',
  GetCIBusinessOwner = '/projects/ciBusinessOwner/:ci',
  ProjectApproval = '/projects/approvalRequest/:approvalId',
  AllApprovalRequestTypes = '/projects/allApproval/requestTypes',
  ProjectProdApprovalResponse = '/projects/prodApprovalRequest/:approvalRequestId/response',
  APIOwnerApproverResponse = '/projects/onboarding/approvalRequest/:approvalRequestId/response',
  ListingTypeBusinessUnitApprovalResponse = '/publishing/approvalRequest/:approvalRequestId/response',
  ContainsUnsupportedPlugin = '/ContainsUnsupportedPlugin',
  ProjectPermissions = '/projects/:projectId/users',
  ProjectEnvironment = '/projects/:projectId/environments/:projectEnvId',
  ProjectApiEnvironment = '/projects/:projectId/api-env',
  ProjectCicdEnvironment = '/projects/:projectId/cicd-env',
  ProjectEnvironmentStatus = '/projects/:projectId/environments/:projectEnvId/status',
  ProjectEnvironmentAuth = '/projects/:projectId/environments/:projectEnvId/auth',
  ProjectEnvironmentServices = '/projects/:projectId/environments/:projectEnvId/services',
  ProjectEnvironmentConnections = '/projects/environments/:projectEnvId/connections',
  ProjectServices = '/projects/:projectEnvId/services',
  ProjectEnvironmentCallbackUrls = '/projects/environments/:projectEnvId/connections/callbackUrls',
  ProjectEnvironmentService = '/projects/:projectId/environments/:projectEnvId/services/:serviceEnvId/:oauthType',
  ProjectEnvironmentServiceDelete = '/projects/:projectId/environments/:projectEnvId/services/:serviceEnvId',
  ProjectPromoteToProd = '/projects/environments/:projectEnvId/promotetoprod',
  ProjectPromoteToQA = '/projects/environments/:projectEnvId/promotetoqa',
  ProjectEnvironmentServiceSecurityScan = '/projects/environments/:projectEnvId/:serviceId/security-scan',
  ProjectEnvironmentPopulateConnectedServices = '/projects/environments/:envId/populateServices',
  ProjectPromoteRequiresApproval = '/projects/environments/:projectEnvId/prodServiceCheck',
  ProjectRegistrations = '/projects/:projectId/services',
  ProjectRegistration = '/projects/services/:serviceId',
  DownloadInvictiScanReport = '/projects/services/report/download/:scanId',
  GetServiceApproverList = '/projects/services/:serviceId/approverList',
  ServiceConnectedProjects = '/projects/:serviceId/consumers',
  ServiceHealthCheckProjectConnections = '/health-check/env/:serviceEnvId/registration-version/:registrationVersionId/project-environment-id/:projectEnvironmentId',
  ServiceHealthCheck = '/health-check/env/:serviceEnvId/registration-version/:registrationVersionId',
  ValidateProxyPath = '/projects/services/check/proxy-path',
  AllProxyPath = '/projects/services/all/proxy-path',
  AllProjectEnvironmentUpstreamUri = '/projects/services/upstream-uri/:projectEnvId',
  IsUpstreamUriUnique = '/projects/services/upstream-uri',
  GetListingForRegisterId = 'projects/services/listing-by-registration-id/:registrationId',
  ValidateResourceName = '/projects/services/check-resource-name/:envMnemonic/',
  ProjectRegistrationVersion = '/projects/services/:serviceId/version/:versionId',
  UserInfo = '/functions/auth/user',
  LogoutUser = '/api/auth/logout',
  RpaOpsDashboard = '/rpa-dashboard/ui/dashboard',
  RpaOpsAddVerification = '/rpa-dashboard/ui/verification/addentry',
  RpaOpsSignOffData = '/rpa-dashboard/ui/dashboard/signoffdata',
  RpaOpsSignOffHistory = '/rpa-dashboard/ui/dashboard/signoffhistory',
  RpaOpsDataReset = '/rpa-dashboard/ui/resetmockdata',
  RpaOpsSignOff = '/rpa-dashboard/ui/signoff/v2',
  RpaOpsUnassignedBots = '/rpa-dashboard/ui/bot/unassigned',
  RpaOpsSignOffEffectiveDates = '/rpa-dashboard/ui/dashboard/signoffdata/effectivedates',
  RpaOpsSignOffDetailsBySignOffId = '/rpa-dashboard/ui/dashboard/signoffDetailsBySignoffid',
  RpaOpsAddVerificationComment = '/rpa-dashboard/ui/verification/addVerificationComment',
  RpaOpsSignOffFilesCaptureNames = '/rpa-dashboard/ui/signoff/files/capturenames',
  RpaOpsSharepointAuthenticate = '/rpa-dashboard/ui/signoff/files/token',

  RpaOpsBlockingTicket = '/rpa-dashboard/ui/openblockingticket',
  RpaOpsIntermittentTicket = '/rpa-dashboard/ui/openintermittentticket',
  RpaOpsNonBlockingTicket = '/rpa-dashboard/ui/opennonblockingticket',
  RpaOpsLogicalBot = '/rpa-dashboard/ui/logicalbot',
  RpaOpsLogicalBotCategories = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/categories',
  RpaOpsLogicalBotAssignees = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/assignees',
  RpaOpsLogicalBotCategory = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/categories/:categoryId',
  RpaOpsLogicalBotAssignee = '/rpa-dashboard/ui/dashboard/logicalbot/:logicalBotId/assignees/:assigneeId',
  RpaOpsEditLogicalBotHistory = '/rpa-dashboard/ui/editlogicalbothistory',
  RpaOpsScheduledReportList = '/rpa-dashboard/ui/dashboard/notifications/email/reports/list',
  RpaOpsScheduledReportCreate = '/rpa-dashboard/ui/dashboard/notifications/email/reports/new',
  RpaOpsScheduledReportEdit = '/rpa-dashboard/ui/dashboard/notifications/email/reports/edit/:reportName',
  RpaOpsScheduledReportView = '/rpa-dashboard/ui/dashboard/notifications/email/reports/view/:reportName',
  RpaOpsScheduledReportDelete = '/rpa-dashboard/ui/dashboard/notifications/email/reports/edit/:reportName',

  GetRegistrations = '/publishing/availableRegistrations',
  GetRegistrationVersions = '/publishing/availableRegistrationVersions/:registrationId',
  GithubDocs = '/listings/docs/github',
  MyListings = '/publishing',
  AllListings = '/publishing/all',
  AddSelfAsOwner = '/publishing/draftListing/:listingId/addSelfAsOwner/:setAsOwner',
  SetListingAsFeatured = '/publishing/draftListing/:listingId/isFeatured/:setAsFeatured',
  GetAdminSettings = '/publishing/draftListing/:listingId/adminSettings',
  ListingTypes = '/publishing/listingTypes',
  ListingConnectedProjects = '/listings/:listingId/projects',

  // publishing
  GetListingTypes = '/publishing/listingTypes',
  GetListingType = '/publishing/listingTypes/:listingTypeId',
  CreateListingType = '/publishing/listingTypes',
  UpdateListingType = '/publishing/listingTypes',
  GetUserGithubRepos = '/publishing/github/user-repos/:mudId',
  GetGithubRepoFiles = '/publishing/github/repo-files',
  GetGithubRepoFileContents = '/publishing/github/file-contents',

  // new draft
  // publish new draft
  // update draft
  // publish existing draft

  // new draft version
  // update draft version

  SaveListingDraft = '/publishing/draftListing',
  SaveListingDraftVersion = '/publishing/draftListing',
  UpdateListingDraftVersion = '/publishing/draftListingVersion/:listingVersionId',

  GetAvailableRegistrations = '/publishing/availableRegistrations',
  GetAvailableRegistrationVersions = '/publishing/availableRegistrationVersions/:registrationId',

  // versioned, aka APIs/Services/Registrations
  GetDraftListingVersion = '/publishing/draftListingVersion/:draftListingVersionId',
  CreateDraftListingVersion = '/publishing/draftListing/:draftListingId/version',
  UpdateDraftListingVersion = '/publishing/draftListingVersion/:draftListingVersionId',
  PublishNewDraftListingVersion = '/publishing/draftListing/:draftListingId/publishNewVersion/',
  PublishDraftListingVersion = '/publishing/draftListingVersion/:draftListingVersionId/publish/',
  DiscardDraftListingVersion = '/publishing/draftListingVersion/:draftListingVersionId/discardDraft',
  DeleteDraftListingVersion = '/publishing/draftListingVersion/:draftListingVersionId',
  UnpublishListingVersion = '/publishing/draftListingVersion/:listingVersionId/unpublish',

  // unversioned, everything else
  GetDraftListing = '/publishing/draftListing/:draftListingId',
  CreateDraftListing = '/publishing/draftListing',
  UpdateDraftListing = '/publishing/draftListing/:draftListingId',
  PublishNewDraftListing = '/publishing/draftListing/publish',
  PublishDraftListing = '/publishing/draftListing/:draftListingId/publish',
  DiscardDraftListing = '/publishing/draftListing/:draftListingId/discardDraft',
  DeleteDraftListing = '/publishing/draftListing/:draftListingId',
  UpdateListingPermissions = '/publishing/draftListing/:draftListingId/permissions',
  UnpublishListing = '/publishing/draftListing/:listingId/unpublish',

  // Try It
  TryItTryOpenApi = '/tryIt',

  Proxy = '/publishing/proxy',
  PandoraIDs = '/air/pandora',

  // RPA Bot Console
  BotHealth = '/rpa-admin/health',
  PromoteBot = '/rpa-admin/bot/promotion',
  BotList = '/rpa-admin/botlist',
  ActiveBotList = '/rpa-admin/active-botlist',
  DevDependecies = '/rpa-admin/devdependencies',
  QaDependecies = '/rpa-admin/qadependencies',
  ValidateFuncAcct = '/rpa-admin/functional-acct',
  PromoteBotFromDevToQa = '/rpa-admin/v3/qa/promotion',
  PromoteBotFromQaToProd = '/rpa-admin/v3/prod/promotion',
  ApprovalDependencies = '/rpa-admin/v3/approval/dependencies',

  // RPA Projects    /projects/v2/:projectId/rpa
  AddBotToProjectV2 = '/rpa-admin/v2/:projectId/rpa',
  AddBotDeployment = '/rpa-admin/addBotDeployment/:botRegistrationId',
  AddBotToProject = '/rpa-admin/:projectId/rpa',
  GetProjectBots = '/rpa-admin/:projectId/rpa',
  UpdateBotDetails = '/rpa-admin/:projectId/rpa/:botRegistrationId',
  UpdateBotAndListingDetails = '/rpa-admin/v2/:projectId/rpa/:botRegistrationId/listing',
  RemoveProjectBot = '/rpa-admin/v1/:projectId/rpa/:botRegistrationId/remove/:listingId',
  RemoveProjectBotDeployment = '/rpa-admin/v1/:projectId/rpa/bot/:botDeploymentId',
  RemoveProjectBotAndListingDetails = '/rpa-admin/v2/:projectId/rpa/:botRegistrationId/listing/:listingId',
  ConfigurationName = '/rpa-admin/service-now/ci-name/:id',
  BotProdPromotionApprovalResponse = '/rpa-admin/approvalRequest/:approvalRequestId/response',

  // Enums
  Enums = '/enums',

  // Process Envs
  ProcessEnvs = '/enums/processEnvs',

  // Feature Flags
  FeatureFlags = '/feature-flags',

  // Admin Listing Types
  AdminListingTypes = '/functions/admin/listing-types',
  AdminListingTypesUpdate = '/functions/admin/listing-types/:id',

  // Admin Application User Roles
  ApplicationTeamRoles = '/functions/admin/application-team-roles',
  ApplicationTeamRolesUpdate = '/functions/admin/application-team-roles/:id',

  // Admin Registration
  AdminRegistrationServiceGetSingle = '/functions/admin/registration/service/',
  AdminRegistrationServiceSetSingle = '/functions/admin/registration/service/',
  AdminRegistrationServiceValidateSingle = '/functions/admin/registration/service/validate/',
  AdminRegistrationSetFormConfiguration = '/functions/admin/registration/formConfiguration/',
  AdminRegistrationGetFormConfiguration = '/functions/admin/registration/formConfiguration/',
  AdminRegistrationGetDefaultFormConfiguration = '/functions/admin/registration/defaultFormConfiguration/',
  AdminRegistrationGetRegistrationFormRenderer = '/functions/admin/registration/loadRegistrationFormRenderer/',
  AdminRegistrationConvertFormConfiguration = '/functions/admin/registration/convert/',

  // Admin Listing Type Business Unit Approvers
  ListingTypeBusinessUnitApprovers = '/functions/admin/listingtypebusinessunitapprovers',
  ServiceManagementRegistrationList = '/functions/admin/servicemanagement/registrationlist',
  ServiceManagementSetRegistrationProvisioned = '/functions/admin/servicemanagement/setregistrationprovisioned',
  ListingTypeBusinessUnitApproversUpdate = '/functions/admin/listingtypebusinessunitapprovers/:listingTypeId/:businessUnitId',
  ListingTypeBusinessUnitApproversDelete = '/functions/admin/listingtypebusinessunitapprovers/:listingTypeId/:businessUnitId',

  // Service Project Movment
  ServiceProjectMovementMigrateServiceList = '/functions/admin/serviceprojectmovement/migratelist',
  ServiceProjectMovementOnboardingToOtherProject = '/functions/admin/serviceprojectmovement/migrate-service-to-other-project',

  OrchestratorMonitorGetAll = '/functions/admin/orchestratormonitor/all',
  OrchestratorMonitorGetSingle = '/functions/admin/orchestratormonitor/single/:instanceId',
  OrchestratorMonitorTerminate = '/functions/admin/orchestratormonitor/terminate',
  OrchestratorMonitorPurgeHistory = '/functions/admin/orchestratormonitor/purgehistory',
  OrchestratorMonitorRestart = '/functions/admin/orchestratormonitor/restart',
  TwoOttersLockingLock = '/functions/admin/two-otters-locking/lock',


  // Notifications
  Notifications = '/notifications',
  NotificationById = '/notifications/:id',
  NotificationStatusUpdate = '/notifications/status/:status',

  // Teams
  UserTeams = '/teams/all',
  AllTeams = 'teams/all/list',
  SearchTeams = '/teams/search/:query',
  DeleteTeam = '/teams/delete/:id',
  CreateTeam = '/teams/create',
  UpdateTeam = '/teams/update/:id',
  TeamById = '/teams/:id',

  // Tokens
  AllTokens = '/tokens',
  GenerateTokens = '/tokens/generate',
  UpdateToken = '/tokens/update/:id',
  DeleteToken = '/tokens/delete/:id',

  // help / docs
  HelpFileTree = '/help/tree',

  // external-services
  PeoplePickerSearch = '/external-services/search/sinequa',

  //Environments
  ProjectClientSecret = '/projects/environments/:envId/client-secret/:ssoSettingId/add',
  ProjectClientSecretDelete = '/projects/environments/:envId/client-secret/:ssoSettingId/:clientSecretId/remove',
  ProjectClientSecretReset = '/projects/environments/:envId/client-secret/:ssoSettingId/reset',
  ProjectClientSecretUpdate = '/projects/environments/:envId/client-secret',

  ProjectApiKey = '/projects/environments/:envId/api-key/add',
  ProjectApiKeyDelete = '/projects/environments/:envId/api-key/:apiKeyId/remove',

  OpenApiToKong = '/open-api-to-kong/convert/',
  KongMonitoringLicenses = '/kong-monitoring/licenses',
  KongMonitoringServices = '/kong-monitoring/services',
  KongMonitoringConsumers = '/kong-monitoring/consumers',
  KongMonitoringConnections = '/kong-monitoring/connections',
  KongHistoryCounts = '/kong-monitoring/konghistorycounts',

  //Budgeting Source
  GetBudgetingSourceList = '/projects/budgeting-source',
  //Prometheus Analytics API
  PrometheusAnalytics_TotalRequest = '/prometheus/totalRequest',
  PrometheusAnalytics_NumberOfErrors = '/prometheus/numberOfErrors',
  PrometheusAnalytics_NumberOfConsumers = '/prometheus/numberOfConsumers',

  //Cross-Charging
  CrossChargingReportData = '/cross-charging/report'
}

export function getProxyUrl(url: string): string {
  return location.origin + URLs.Base + URLs.Proxy + '?url=' + encodeURIComponent(url);
}

export function safeUrl(url:string){
  const urlSpec = new URL(url);
  const parts = [
    urlSpec.protocol,
    urlSpec.host,
    urlSpec.pathname === '/'? '' : urlSpec.pathname
  ];
  return `${JSON.stringify(parts)}`;
}

export function safeUrlRevert(url: string): string {
  //reassemble safeUrl -> string
  const urls = JSON.parse(url) || [];
  if(urls.length > 0) {
    return `${urls[0]}//${urls[1]}${urls[2]}` ;
  }
  return url;
}

export default URLs;
