import { register } from 'register-service-worker';

register(`/serviceWorker.js`, {
  registered(reg) {
    console.log('Service worker /serviceWorker has been registered.');
    setInterval(() => {
      reg.update();
    }, 1000 * 60 * 60); // e.g. hourly checks
  },
});
