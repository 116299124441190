
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { TopAppBar } from '@gsk-tech/gsk-top-app-bar/gsk-top-app-bar';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';
import GButton from '@/components/gsk-components/GskButton.vue';
import NotificationTab from '@/components/NotificationTab.vue';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import { UINavigationItem } from '@/types';
import TopNavigation from '@/components/TopNavigation.vue';
import NavigationList from '@/components/NavigationList.vue';
import { UserModule } from '@/store/modules/user.module';
import UserCircle from '@/components/UserCircle.vue';
import { BaseUser } from '@/types/users.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { currentYear } from '@/utils/time';

type GskMenu = Menu;

@Component({
  components: {
    GButton,
    TopNavigation,
    NavigationList,
    UserCircle,
    NotificationTab,
  },
})
class DefaultLayout extends Vue {
  private navOpen = false;

  @Watch('$route')
  onRouteChange() {
    this.navOpen = false;
  }

  get currentYear() {
    return currentYear();
  }

  get navLinks(): UINavigationItem[] {
    const links: UINavigationItem[] = [
      {
        text: 'My Listings',
        key: 'l',
        route: { name: RouteNames.MyListings },
      },
      {
        text: 'Projects',
        key: 'p',
        route: { name: RouteNames.ProjectsList },
      },
      {
        text: 'Product Catalog',
        key: 'c',
        route: { name: RouteNames.Listings },
      },
    ];
    if (FeatureFlagsModule.cloudEnabled) {
      links.push({
        text: 'Cloud Portal',
        key: 'ccoe',
        route: '/cloud-portal',
      });
    }

    return links;
  }

  showMenu(): void {
    const menu = this.$refs.menu as GskMenu;
    const anchor = this.$refs.menuAnchor as Vue;
    menu.setAnchorElement(anchor.$el);
    menu.setAnchorCorner(menu.Corner.BOTTOM_LEFT);
    menu.open = !menu.open;
  }

  logout() {
    UserModule.logoutUser();
    ProcessEnvsModule.getAllProcessEnvs();
    window.location.assign(ProcessEnvsModule.processEnvs.oidcLogoutUri);
  }

  get myTeams(): RawLocation {
    return {
      name: RouteNames.MyTeams,
    };
  }

  get myTokens(): RawLocation {
    return {
      name: RouteNames.MyTokens,
    };
  }

  get user(): BaseUser {
    return UserModule.user;
  }

  get switchAccount(): RawLocation {
    return {
      name: RouteNames.SwitchAccount,
    };
  }

  get helpRoute() {
    return `/${HELP_ROOT_PATH}`;
  }

  get drawerEventHandlers() {
    return {
      opened: () => {
        this.navOpen = true;
      },
      closed: () => {
        this.navOpen = false;
      },
    };
  }

  get notificationsEnabled() {
    return FeatureFlagsModule.notificationsEnabled;
  }

  mounted() {
    const bar = this.$refs.appBar as TopAppBar;
    const main = this.$refs.main as HTMLElement;
    bar.scrollTarget = main;
  }
}

export default DefaultLayout;
