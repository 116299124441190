import { BaseUser } from './users.types';
import { FormField } from '@/components/form/form.types';
import { GithubRepo } from '@/types/publishing.types';
import { ProjectTeam } from '@/types/teams.types';
import { SnowDetailListing } from '@/types/rpa-admin.types';
import { ProjectEnvironment } from '@/types/projects.types';

export interface Listing {
  authenticationType: string;
  listingId?: number;
  currentVersionId?: number;
  listingVersionId?: number;
  listingTypeId: number;
  listingTypeName?: string;
  listingName: string;
  listingDescription: string;
  hasImage: boolean;
  iconUrl?: string;
  listingTeamName?: string;
  listingTeamIcon?: string;
  isFeaturedFlag?: boolean;
  isBillableFlag?: boolean;
  createTimestamp?: string;
  updateTimestamp?: string;
  publishTimestamp?: string;
  statusName?: string;
  statusId?: number;
  keywordList?: string[];
  owners: BaseUser[];
  versions: ListingVersion[];
  rnk?: number;
  seq_num?: number;
  requiresApproval?: boolean;
  platform?: Record<string, string>;
}

export interface DraftListingId {
  listingId: number;
}

export interface GetRegistrationsResponseData {
  resourceName: string;
  registrationId: number;
  resourceDescription: string;
  businessUnitId: number | null;
}

export interface GetRegistrationsResponseBody {
  count: number;
  data: GetRegistrationsResponseData[];
}

export interface ListingTypeCategory {
  categoryDescription: string;
  categoryId: number;
  categoryName: string;
  categorycount: number;
}

export interface ListingType {
  listingTypeId: number;
  name: string;
  template: ListingTypeTemplate;
  category: ListingTypeCategory[];
}

export interface ListingUser extends BaseUser {
  roleId: number;
  roleName: string;
  isEditPrivilege?: boolean;
}

export interface ListingVersion {
  listingVersionId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extendedProperties: any;
  createTimestamp: string;
  updateTimestamp: string;
  publishTimestamp: string;
  registrationVersionId: number;
  statusId: number;
  versionId: number;
}

export interface ListingSectionOptions {
  hasOptions?: boolean, // added to bypass tslint error
}

export interface APIListingSectionOptions extends ListingSectionOptions {
  tryItEnabled: boolean;
}

export interface ListingSection {
  sectionName: string;
  sectionTemplateType: string;
  /** @deprecated don't use this for anything, use sectionTemplateType */
  sectionTemplateTypeId: number;
  sectionContent: string;
  sectionContentUrl?: string;
  sectionOptions?: ListingSectionOptions;
}

export interface FullListing extends Listing {
  publishedBy: BaseUser;
  extendedProperties: ListingExtendedProperties;
  registrationVersionId?: number;
  registrationId?: number;
  listingUsers?: ListingUser[];
  teams: ProjectTeam[];
}

export type SavedFullListing = FullListing & {
  extendedProperties: ListingExtendedProperties<true>;
  hasImage?: boolean;
  versionId?: number;
};

export interface HealthCheck {
  environmentId: number;
  healthCheckPath: string;
  healthCheckPattern: string
};

export type ListingCardListing = FullListing & {
  extendedProperties?: ListingExtendedProperties<true>;
  details?: ListingExtendedProperties<true>['details'];
  botRegistrationId?: number;
  sourceProjectId?: number;
  hasImage?: boolean;
  versionId: number;
  isPrivate: boolean;
  healthCheck?: HealthCheck[],
  snowData?: SnowDetailListing;
  botDetails?: { botRegistrationId: number; projectId: number };
};

export interface DraftListing extends Listing {
  listingUsers: ListingUser[];
  extendedProperties: ListingExtendedProperties;
  registrationVersionId?: number;
  registrationId?: number;
  hasImage: boolean;
  businessUnitId: number;
  categoryId: number;
}

export interface RpaDetail {
  key: string;
  type: string;
  value: string;
}

export interface RpaListing {
  createTimestamp: string;
  currentVersionId: number | null;
  details: RpaDetail[];
  hasImage: boolean;
  isBillableFlag: boolean;
  isFeaturedFlag: boolean;
  listingDescription: string;
  listingId: number;
  listingName: string;
  listingTypeId: number;
  registrationId: number | null;
  rnk: null | string | number;
  seq_num: number;
  updateTimestamp: string;
}

interface DLM {
  listingName: string;
  listingDescription: string;
  listingTypeId: number;
  hasImage: boolean;
  extendedProperties: ListingExtendedProperties;
  listingUsers: ListingUser[];
  teams: ProjectTeam[];
  categoryId: number;
  businessUnitId: number;
  isPrivate: boolean;
  publishMessage: string;
  approvalRequired?: boolean;
  changeReason?: string;
}
export interface DraftListingModule extends DLM {
  registrationId: number;
  registrationVersionId: number;
}

export interface DraftListingModulePayload extends DLM {
  registrationId?: number;
  registrationVersionId?: number;
  listingId?: number;
}

export type SavedDetails = Pick<FormField, 'key' | 'value' | 'type'>[];
export interface ListingExtendedProperties<T extends boolean = false> {
  githubRepo: GithubRepo | null;
  image: string;
  keywords: string[];
  documentationSections: DocumentationSection[];
  details: T extends false ? FormField[] : SavedDetails;
}

export type SavedListingModule = DraftListingModule & {
  extendedProperties: ListingExtendedProperties<true>;
};

export interface DraftListingVersion extends DraftListing {
  listingVersionId: number;
}

export interface Listings<T extends SavedListingModule = SavedListingModule> {
  listingTypeId: number;
  listingTypeName: string;
  sequenceNumber: number;
  count: number;
  listings: T[];
}

export type ListingSectionType = 'markdown' | 'open-api';

export interface DocumentationTemplate {
  canLinkContent: boolean;
  canEditContent: boolean;
  maxSections: number;
  canAddSections: boolean;
  allowedSectionTypes: ListingSectionType[];
  defaultSections: DocumentationSection[];
}

export interface DocumentationSection {
  sectionInfo: ListingSection;
  meta: {
    canDelete: boolean;
    canRename: boolean;
    canReorder: boolean;
    canLinkContent: boolean;
    canEditContent: boolean;
  };
}

export interface ListingGithubRepoTemplate {
  required: boolean;
  canLink: boolean;
  templateOnly?: boolean;
  repo: GithubRepo | null;
}

export interface ListingTypeTemplate {
  publishing: {
    content: ListingContentTemplate;
    githubRepo: ListingGithubRepoTemplate;
    info: {
      acceptImage: boolean;
    };
    details: {
      isCustomComponent: boolean;
      form: {
        fields: FormField[];
      };
    };
    documentationSections: DocumentationTemplate;
  };
}

export interface OptionalHeadingFields {
  heading?: string;
  subheading?: string;
}
export interface ListingContentTemplate {
  details: OptionalHeadingFields & { edit: OptionalHeadingFields };
  productCard: OptionalHeadingFields;
  documentation: OptionalHeadingFields;
}

export interface ListingConnectedProjectTargetService {
  environmentId: number;
  environmentName: string;
  serviceId: number;
  kongProxyPath: string[];
}
export interface ListingConnectedProject {
  environments: (Partial<ProjectEnvironment> & {
    targetServices: ListingConnectedProjectTargetService[],
  })[],
  projectId: number;
  projectName: string;
}

export interface ListingConnectedProjectsResponse {
  projects: ListingConnectedProject[];
}
