import { BaseUser } from '@/types/users.types';

export type AnalyticsUser = Pick<BaseUser, 'mudId' | 'email'>;

export enum AnalyticsEventNames {
  PageView = 'PAGE_VIEW',
  Click = 'CLICK',
  UserLogin = 'USER_LOGIN',
}

export interface ClickData extends Record<string, any> {
  clickTarget: string;
}

export type PageViewData = Record<string, any>;
