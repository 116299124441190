import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DeepReadonly } from 'ts-essentials';
import store from '@/store';
import { ProcessEnvsBase } from '@/types/process-envs.types';
import * as API from '@/api/process-envs.api';
import { Platform } from '@/types/enum.types';

@Module({ name: 'processEnvs', store, dynamic: true, namespaced: true })
class ProcessEnvironmentsModule extends VuexModule {
  public loading = false;
  public _platform: string = Platform.AZURE
  private processEnvsLocal: ProcessEnvsBase = {
    federationURL: { Dev: '', QA: '', Prod: '' },
    azureADTenantId: '',
    apiGatewayURLMapping: '',
    oidcLogoutUri: '',
    searchBaseUrl: '',
    githubApiUrl: '',
    msGraphUrl: '',
    msVsspsUrl: '',
    msDevAzureUrl: '',
    helpRepoName: '',
    helpRepoBranch: '',
    helpRepoOrg: '',
    delayedProcessingInfo: '',
    maintenanceModeMessage: '',
    budgetingSourceTooltipText: '',
    budgetingSourcePromoteText: '',
  };
  private processEnvPromise: Promise<ProcessEnvsBase> = Promise.resolve(this.processEnvsLocal);

  public get processEnvs(): DeepReadonly<ProcessEnvsBase> {
    return this.processEnvsLocal;
  }

  public get updateComplete(): Promise<void> {
    return this.processEnvPromise.then(() => void 0);
  }

  public get getApiGatewayUrlMapping(): DeepReadonly<Map<string, string>> {
    const _apiUrls: Map<string, string> = new Map([
      ['DEV', this.processEnvsLocal.apiGatewayURLMapping[this._platform].DEV],
      ['QA', this.processEnvsLocal.apiGatewayURLMapping[this._platform].QA],
      ['PROD', this.processEnvsLocal.apiGatewayURLMapping[this._platform].PROD],
    ]);
    return _apiUrls;
  }
  @Mutation
  public setPlatform(platform:string) {
    this._platform = platform;
  }
  @Mutation
  private setProcessEnvPromise(enums: Promise<ProcessEnvsBase>): void {
    this.loading = true;
    this.processEnvPromise = enums;
  }

  @Mutation
  private setProcessEnvValues(newProcessEnvs: ProcessEnvsBase): void {
    this.processEnvsLocal = newProcessEnvs;
    this.loading = false;
  }

  @Action({ rawError: true })
  public async getAllProcessEnvs(): Promise<void> {
    const prom = API.getAllProcessEnvs();
    this.setProcessEnvPromise(prom);
    const processEnvResponse = await prom;
    this.setProcessEnvValues(processEnvResponse);
  }
}

export const ProcessEnvsModule = getModule(ProcessEnvironmentsModule);
