import { Location, Route } from 'vue-router';
import mapKeys from 'lodash/mapKeys';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  AnalyticsEventNames,
  AnalyticsUser,
  ClickData,
  PageViewData,
} from '@/analytics/analytics.types';
import { UserModule } from '@/store/modules/user.module';
import store from '@/store';

export * from '@/analytics/analytics.types';

let appInsights: ApplicationInsights | null = null;
function getAppInsightsInstance(): ApplicationInsights | null {
  const INSTRUMENTATIONKEY = process.env.VUE_APP_APPINSIGHTS_CLIENT_INSTRUMENTATIONKEY;
  if (!INSTRUMENTATIONKEY) {
    return null;
  }
  if (appInsights) {
    return appInsights;
  }
  const instance = new ApplicationInsights({
    config: {
      instrumentationKey: INSTRUMENTATIONKEY,
    },
  });
  instance.loadAppInsights();
  appInsights = instance;
  return instance;
}

function getRouteData(override?: { to: Route; from: Route }) {
  const routeState = store.state.route;
  return {
    current: {
      name: override?.to.name ?? routeState.name,
      path: override?.to.fullPath ?? routeState.fullPath,
    },
    previous: {
      name: override?.from.name ?? routeState.from?.name ?? null,
      path: override?.from.fullPath ?? routeState.from?.fullPath ?? null,
    },
  };
}

function getUserData(): AnalyticsUser {
  return {
    mudId: UserModule.user.mudId,
    email: UserModule.user.email,
  };
}

// never change this, unless the analytics team asks us to
const ANALYTICS_EVENT_NAME = 'analytics';
const ANALYTICS_QUERY_PREFIX = '__analytics__:';

function getAnalyticsData<T>(
  name: AnalyticsEventNames,
  data: T,
  routeOverride?: { to: Route; from: Route },
) {
  return {
    detail: {
      name,
      user: getUserData(),
      page: getRouteData(routeOverride),
      data,
    },
  };
}

export function analyticsClick(data: ClickData) {
  const analyticsData = getAnalyticsData(AnalyticsEventNames.Click, data);
  const insights = getAppInsightsInstance();
  insights?.trackEvent({ name: AnalyticsEventNames.Click }, analyticsData);
}

export function analyticsUserLogin() {
  const analyticsData = getAnalyticsData(AnalyticsEventNames.UserLogin, {});
  const insights = getAppInsightsInstance();
  insights?.trackEvent({ name: AnalyticsEventNames.UserLogin }, analyticsData);
}

export function analyticsPageView(data: PageViewData, routeOverride?: { to: Route; from: Route }) {
  const analyticsData = getAnalyticsData(AnalyticsEventNames.PageView, data, routeOverride);
  const insights = getAppInsightsInstance();
  insights?.trackPageView({
    name: String(routeOverride?.to.name),
    refUri: routeOverride?.from.fullPath,
    isLoggedIn: !!analyticsData.detail.user,
    properties: analyticsData,
  });
}

/**
 * this is kind of dumb but vue-router doesn't have a way to
 * pass arbitrary metadata through route objects
 */
export function addAnalyticsRouteParams(
  params: Location['params'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
): Location['params'] {
  return {
    ...params,
    ...mapKeys(data, (value, key) => {
      return ANALYTICS_QUERY_PREFIX + key;
    }),
  };
}

export function extractAnalyticsFromRouteParams(
  params: Location['params'],
): Record<string, unknown> {
  const out: Record<string, unknown> = {};
  if (params) {
    Object.keys(params).forEach(key => {
      if (key.startsWith(ANALYTICS_QUERY_PREFIX)) {
        out[key.replace(ANALYTICS_QUERY_PREFIX, '')] = params[key];
        delete params[key];
      }
    });
  }
  return out;
}
