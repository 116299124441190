import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import keyBy from 'lodash/keyBy';
import { DeepReadonly } from 'ts-essentials';
import Vue from 'vue';
import store from '@/store';
import { FeatureFlag, FeatureFlagMnemonics, FeatureFlags } from '@/types/feature-flags.types';
import { createFeatureFlag, getFeatureFlags, updateFeatureFlag } from '@/api/feature-flags.api';
import { First } from '@/types/util.types';

@Module({ name: 'featureFlags', store, dynamic: true, namespaced: true })
class Mod extends VuexModule {
  public hasFeatureFlags = false;
  public loading = false;
  private featureFlagsData: FeatureFlags = {
    REGISTRATION: getFeatureFlagPlaceholder(FeatureFlagMnemonics.REGISTRATION),
    REGISTRATIONNEWFIELDS: getFeatureFlagPlaceholder(FeatureFlagMnemonics.REGISTRATIONNEWFIELDS),
    CLOUDPORTAL: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CLOUDPORTAL),
    HEALTHCHECK: getFeatureFlagPlaceholder(FeatureFlagMnemonics.HEALTHCHECK),
    NOTIFICATIONS: getFeatureFlagPlaceholder(FeatureFlagMnemonics.NOTIFICATIONS),
    OPPASS: getFeatureFlagPlaceholder(FeatureFlagMnemonics.OPPASS),
    BOTPROMOTION: getFeatureFlagPlaceholder(FeatureFlagMnemonics.BOTPROMOTION),
    KONGENABLED: getFeatureFlagPlaceholder(FeatureFlagMnemonics.KONGENABLED),
    BOTPRODPROMOTION: getFeatureFlagPlaceholder(FeatureFlagMnemonics.BOTPRODPROMOTION),
    RPAOPS: getFeatureFlagPlaceholder(FeatureFlagMnemonics.RPAOPS),
    AZUREAD: getFeatureFlagPlaceholder(FeatureFlagMnemonics.AZUREAD),
    BOTCATALOG: getFeatureFlagPlaceholder(FeatureFlagMnemonics.BOTCATALOG),
    RPAOPSADDVERIFICATION: getFeatureFlagPlaceholder(FeatureFlagMnemonics.RPAOPSADDVERIFICATION),
    CICD: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CICD),
    PING: getFeatureFlagPlaceholder(FeatureFlagMnemonics.PING),
    A360: getFeatureFlagPlaceholder(FeatureFlagMnemonics.A360),
    TRYIT: getFeatureFlagPlaceholder(FeatureFlagMnemonics.TRYIT),
    DYNAMICREGISTRATION: getFeatureFlagPlaceholder(FeatureFlagMnemonics.DYNAMICREGISTRATION),
    CIIDPERSERVICE: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CIIDPERSERVICE),
    APISECURITYSCAN: getFeatureFlagPlaceholder(FeatureFlagMnemonics.APISECURITYSCAN),
    CUSTDEV: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CUSTDEV),
    CUSTQA: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CUSTQA),
    CUSTPROD: getFeatureFlagPlaceholder(FeatureFlagMnemonics.CUSTPROD),
    GCP: getFeatureFlagPlaceholder(FeatureFlagMnemonics.GCP),
    PLATFORM: getFeatureFlagPlaceholder(FeatureFlagMnemonics.PLATFORM),
    OPENAPISPEC: getFeatureFlagPlaceholder(FeatureFlagMnemonics.OPENAPISPEC),
    BUDGETINGSOURCE: getFeatureFlagPlaceholder(FeatureFlagMnemonics.BUDGETINGSOURCE),
    PROMETHEUSANALYTICSCHART: getFeatureFlagPlaceholder(FeatureFlagMnemonics.PROMETHEUSANALYTICSCHART),
    KONGCIRCUITBRAKE: getFeatureFlagPlaceholder(FeatureFlagMnemonics.KONGCIRCUITBRAKE),
    PROMOTETOPRODNOTIFY: getFeatureFlagPlaceholder(FeatureFlagMnemonics.PROMOTETOPRODNOTIFY),
    PROMOTETOQANOTIFY: getFeatureFlagPlaceholder(FeatureFlagMnemonics.PROMOTETOQANOTIFY)
  };
  private featureFlagPromise: Promise<FeatureFlags> = Promise.resolve(this.featureFlagsData);

  public get updateComplete(): Promise<void> {
    return this.featureFlagPromise.then(() => undefined);
  }

  public get featureFlags(): DeepReadonly<FeatureFlags> {
    return this.featureFlagsData;
  }

  public get registrationEnabled() {
    return this.featureFlags.REGISTRATION.featureFlagEnabled;
  }

  public get cloudEnabled() {
    return this.featureFlags.CLOUDPORTAL.featureFlagEnabled;
  }

  public get notificationsEnabled() {
    return this.featureFlags.NOTIFICATIONS.featureFlagEnabled;
  }

  public get botPromotionEnabled() {
    return this.featureFlags.BOTPROMOTION.featureFlagEnabled;
  }

  public get kongEnabled() {
    return this.featureFlags.KONGENABLED.featureFlagEnabled;
  }

  public get botProdPromotionEnabled() {
    return this.featureFlags.BOTPRODPROMOTION.featureFlagEnabled;
  }

  public get azureADEnabled() {
    return this.featureFlags.AZUREAD.featureFlagEnabled;
  }

  public get rpaBotCatalog() {
    return this.featureFlags.BOTCATALOG.featureFlagEnabled;
  }

  public get pingEnabled() {
    return this.featureFlags.PING.featureFlagEnabled;
  }

  public get cicdEnabled() {
    return this.featureFlags.CICD.featureFlagEnabled;
  }

  public get a360Enabled() {
    return this.featureFlags.A360.featureFlagEnabled;
  }

  public get tryitEnabled() {
    return this.featureFlags.TRYIT.featureFlagEnabled;
  }

  public get dynamicRegistrationEnabled() {
    return this.featureFlags.DYNAMICREGISTRATION.featureFlagEnabled;
  }

  public get openApiSpecEnabled() {
    return this.featureFlags.OPENAPISPEC.featureFlagEnabled;
  }

  public get ciIdPerServiceEnabled() {
    return this.featureFlags.CIIDPERSERVICE.featureFlagEnabled;
  }

  public get apiSecurityScanFeatureEnabled() {
    return this.featureFlags.APISECURITYSCAN.featureFlagEnabled;
  }
  
  public get maintenanceModeEnabledDev() {
    return this.featureFlags.CUSTDEV.featureFlagEnabled;
  }

  public get maintenanceModeEnabledQA() {
    return this.featureFlags.CUSTQA.featureFlagEnabled;
  }

  public get maintenanceModeEnabledProd() {
    return this.featureFlags.CUSTPROD.featureFlagEnabled;
  }

  public get gcpEnabled() {
    return this.featureFlags.GCP.featureFlagEnabled;
  }

  public get platformEnabled() {
    return this.featureFlags.PLATFORM.featureFlagEnabled;
  }

  public get budgetingSourceEnabled() {
    return this.featureFlags.BUDGETINGSOURCE.featureFlagEnabled
  }

  public get prometheusAnalyticsChartEnabled() {
    return this.featureFlags.PROMETHEUSANALYTICSCHART.featureFlagEnabled;
  }

  public get kongCircuitBrakeEnbled() {
    return this.featureFlags.KONGCIRCUITBRAKE.featureFlagEnabled;
  }

  public get promoteToProdNotify (){
    return this.featureFlags.PROMOTETOPRODNOTIFY.featureFlagEnabled
  }

  public get promoteToQaNotify (){
    return this.featureFlags.PROMOTETOQANOTIFY.featureFlagEnabled
  }

  @Mutation
  private setFeatureFlagType(flags: FeatureFlags): void {
    this.hasFeatureFlags = true;
    // merge to prevent losing the default flags defined in code if they don't exist in DB yet
    this.featureFlagsData = { ...this.featureFlagsData, ...flags };
    this.loading = false;
  }

  @Mutation
  private setFeatureFlagPromise(flags: Promise<FeatureFlags>): void {
    this.loading = true;
    this.featureFlagPromise = flags;
  }

  @Mutation
  private setFeatureFlag(flag: FeatureFlag) {
    const original = this.featureFlagsData[flag.mnemonic];
    Vue.set(this.featureFlagsData, flag.mnemonic, { ...original, ...flag });
  }

  @Action({ rawError: true })
  public async getFeatureFlags(): Promise<void> {
    const prom = getFeatureFlags().then(convert);
    this.setFeatureFlagPromise(prom);
    const featureFlagResponse = await prom;
    this.setFeatureFlagType(featureFlagResponse);
  }

  @Action({ rawError: true })
  public async createFeatureFlag(payload: First<Parameters<typeof createFeatureFlag>>) {
    const flag = await createFeatureFlag(payload);
    this.setFeatureFlag(flag.data);
    return flag.data;
  }

  @Action({ rawError: true })
  public async updateFeatureFlag(payload: First<Parameters<typeof updateFeatureFlag>>) {
    const flag = await updateFeatureFlag(payload);
    this.setFeatureFlag(flag.data);
    return flag.data;
  }
}

export const FeatureFlagsModule = getModule(Mod);

function getFeatureFlagPlaceholder(mn: FeatureFlagMnemonics): FeatureFlag {
  return {
    mnemonic: mn,
    featureFlagId: -1,
    featureFlagDescription: '',
    featureFlagName: '',
    featureFlagEnabled: false,
    sequenceNumber: -1,
    featureEnabledTeams: [],
  };
}

function convert(flags: FeatureFlag[]): FeatureFlags {
  return keyBy(flags, flag => flag.mnemonic) as FeatureFlags;
}
