import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import * as API from '@/api/notifications.api';
import { NotificationsObject, NotificationsPayload } from '@/types/notifications.types';
import { getAllApprovalRequestTypes } from '@/api/registration.api';


@Module({ name: 'notifications', store, dynamic: true, namespaced: true })
class Mod extends VuexModule {
  public notifications: NotificationsObject = {
    totalNotificationCount: -1,
    totalRequestCount: -1,
    unreadNotificationCount: -1,
    unreadRequestCount: -1,
    totalQueryCount: -1,
    notifications: [],
  };

  public allApprovalRequestTypeData: any = [];

  @Mutation
  public setNotifications(notifications: NotificationsObject): void {
    this.notifications = notifications;
  }

  @Mutation
  public setAllApprovalRequestTypes(data: any): void {
    const filteredData = data.map((item: any) => {
      return { label: item.name, value: item.approvalRequestTypeId }
    })
    this.allApprovalRequestTypeData = filteredData;
  }

  @Action({ commit: 'setNotifications', rawError: true })
  public async getNotifications(payload: NotificationsPayload): Promise<NotificationsObject> {
    return (
      await API.getNotifications(
        payload.filter,
        payload.requestsOnly,
        payload.query,
        payload.results,
        payload.page,
        payload.approvalRequestTypes,
      )
    ).data;
  }

  @Action({ commit: 'setAllApprovalRequestTypes' })
  public async allApprovalRequestTypes() {
    return (await getAllApprovalRequestTypes()).data;
  }
}

export const NotificationsModule = getModule(Mod);
