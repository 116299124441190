import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  NotificationsObject,
  Notification,
  ApprovalNotification,
} from '@/types/notifications.types';

export function getNotifications(
  filter: string[] | undefined = undefined,
  requestsOnly = false,
  query: string | null = null,
  results = 10,
  page = 1,
  approvalRequestTypes: number[] = [],
): AxiosPromise<NotificationsObject> {
  if (query === '') {
    query = null;
  }
  let f: null | string;
  if (filter?.length) {
    f = JSON.stringify(filter.map(s => ({ statusId: +s })));
  } else {
    f = null;
  }

  const ar = JSON.stringify(approvalRequestTypes);
  return axios.get(URLs.Notifications, {
    params: { requestOnly: requestsOnly, query: query, results: results, page: page, approvalRequestTypes: ar, filter: f },
  });
}

export function getNotificationById(id: number): AxiosPromise<Notification | ApprovalNotification> {
  return axios.get(URLs.NotificationById, { params: { id: id } });
}

export function updateNotificationStatus(
  notificationIds: number[],
  updateAll: boolean,
  status: string,
): AxiosPromise<boolean> {
  return axios.put(
    URLs.NotificationStatusUpdate,
    {
      notificationIds: notificationIds,
      updateAll: updateAll,
    },
    { params: { status: status } },
  );
}
