import { SelectOption } from '@/components/form/form.types';

export interface AppEnum {
  readonly id: number;
  readonly name: string;
  readonly mnemonic: string;
  readonly description: string;
  readonly sequenceNumber: number;
}

export interface EmptyAppEnum {
  readonly id: -1;
  readonly name: '';
  readonly mnemonic: '';
  readonly description: '';
  readonly sequenceNumber: -1;
}

export const ensureAppEnum = (appEnum?: AppEnum): AppEnum | EmptyAppEnum =>
  appEnum || {
    id: -1,
    name: '',
    mnemonic: '',
    description: '',
    sequenceNumber: -1,
  };

export type AppEnumKeys =
  | 'role'
  | 'status'
  | 'privilege'
  | 'roleGroup'
  | 'statusType'
  | 'environment'
  | 'environmentType'
  | 'apiEnvironment'
  | 'listingType'
  | 'connectionType'
  | 'businessUnit'
  | 'notificationFilter'
  | 'registrationType'
  | 'botSystems';

export type AppEnumsBase = Record<AppEnumKeys, AppEnum[]>;
export type AppEnums = Record<AppEnumKeys, Record<AppEnum['mnemonic'], AppEnum>>;
export type AppEnumsSelectOptions = Record<AppEnumKeys, SelectOption<AppEnum>[]>;

export enum Status {
  Provisioned = 'PROVISIONED',
  Provisioning = 'PROVISIONING',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  PendingApproval = 'PENDINGAPPROVAL',
  Approved = 'APPROVED',
  Deleting = 'DELETING',
  Scanning = 'SCANNING',
  Scanned = 'SCANNED',
  Insecure = 'INSECURE'
  // "NOTIFICATIONEMAILNOTSENT",
  // "NOTIFICATIONEMAILSENT",
  // "NOTIFICATIONUNREAD",
  // "NOTIFICATIONREAD",
  // "APPROVALPREEMPTED",
  // "SUCCESS",
  // "APPROVEDANDPROVISIONED",
  // "KEYSRECEIVED",
  // "ACTIVE",
  // "DELETED",
  // "FAILED",
}

export const EnvironmentType = {
  RPA: 'RPA',
  API: 'API',
  CICD: 'CICD',
} as const;

export type IEnvironmentTypeMnemonic = keyof typeof EnvironmentType;

export const Environment = {
  CICD: 'CICD',
  DEV: 'DEV',
  QA: 'QA',
  PROD: 'PROD',
  RPA_DEV: 'RPA_DEV',
  RPA_QA: 'RPA_QA',
  RPA_PROD: 'RPA_PROD',
} as const;

export type IEnvironmentMnemonic = keyof typeof Environment;

export enum Platform {
  GCP = "GCP",
  AZURE = "AZURE",
}
