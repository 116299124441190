import axios, { AxiosPromise } from 'axios';
import { default as service } from '@/api/service';
import URLs from '@/api/service/urls';
import { IAppUser } from '@/types/users.types';

export function getUser(): AxiosPromise<IAppUser> {
  return service.get(URLs.UserInfo);
}

export function logoutUser(): AxiosPromise<unknown> {
  return axios.get(URLs.LogoutUser);
}
