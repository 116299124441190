import Vue from 'vue';

Vue.directive('visible', (el, binding) => {
  const visible = !!binding.value;
  if (binding.arg === 'opacity') {
    el.style.transition = 'opacity 0.4s';
    el.style.opacity = visible ? '1' : '0';
  } else {
    el.style.visibility = visible ? 'visible' : 'hidden';
  }
});
