
import { Component, Vue } from 'vue-property-decorator';
import { Snackbar } from '@gsk-tech/gsk-snackbar/gsk-snackbar';
import { Events } from '@/constants';
import { SnackbarOptions } from '@/types';

@Component({})
export default class GlobalSnackbar extends Vue {
  public snackbarOptions: SnackbarOptions = { labelText: '' };

  showSnackbar(options: SnackbarOptions): void {
    this.snackbarOptions = options;
    Vue.nextTick(() => {
      const snack = this.$refs.snack as Snackbar | undefined;
      if (snack) {
        snack.open();
      }
    });
  }
  created() {
    this.$root.$on(Events.ShowSnackbar, (options: SnackbarOptions) => this.showSnackbar(options));
  }
}
