export interface NotificationsObject {
  totalNotificationCount: number;
  totalRequestCount: number;
  unreadNotificationCount: number;
  unreadRequestCount: number;
  totalQueryCount: number;
  notifications: (Notification | ApprovalNotification)[];
}

export interface NotificationsPayload {
  filter?: string[];
  requestsOnly: boolean;
  query: string | null;
  results: number;
  page: number;
  approvalRequestTypes?: number[];
}

export interface ApiApprovalDetails {
  authenticationType: string;
  businessJustification: string;
  hasCustomHeader: boolean;
  headers: string[];
  healthCheckPath: null;
  healthCheckPattern: null;
  kong_proxy_path: string;
  kong_service_name: string;
  notificationId: number;
  pandoraId: string;
  projectDescription: string;
  projectId: number;
  projectName: string;
  resourceDescription: string;
  resourceName: string;
  smartControlsCompleted: boolean;
  timeout: string;
  upstream: string;
  wastTestCompleted: boolean;
  whiteListGroupName: string;
}

export interface ApprovalNotification<T = unknown> extends BaseNotification {
  approvalMessage: string;
  approvalRequestDetails: T;
  approvalRequestId: number;
  approvalRequestTimestamp: string;
  approvalRequestTypeId: number;
  approvalRequestTypeMnemonic: ApprovalType;
  approvalRequestTypeName: string;
  approvalStatusId: number;
  approvalStatusMnemonic: ApprovalStatus;
  approvalStatusName: string;
  requestorEmail: string;
  requestorFullName: string;
  requestorMudId: string;
  requestorUserId: number;
  lastApprover: null | {
    email: string;
    mudId: string;
    fullName: string;
    statusId: number;
    statusMnemonic: ApprovalStatus;
    approvalMessage: string;
  };
  otherApprovers: null | {
    email: string;
    mudId: string;
    fullName: string;
  }[];
}

export interface BaseNotification {
  createTimestamp: string;
  createUserId: number;
  lastUpdateUserId: number;
  notificationId: number;
  notificationMessage: string;
  notificationTitle: string;
  statusId: number;
  statusMnemonic: string;
  statusName: string;
  updateTimestamp: string;
}

export interface Notification extends BaseNotification {
  approvalMessage: null;
  approvalRequestDetails: null;
  approvalRequestId: null;
  approvalRequestTimestamp: null;
  approvalRequestTypeId: null;
  approvalRequestTypeMnemonic: null;
  approvalRequestTypeName: null;
  approvalStatusId: null;
  approvalStatusMnemonic: null;
  approvalStatusName: null;
  requestorEmail: null;
  requestorFullName: null;
  requestorMudId: null;
  requestorUserId: null;
}

export function isApprovalNotification(
  n: Notification | ApprovalNotification,
): n is ApprovalNotification {
  return n.approvalRequestTypeMnemonic !== null;
}

export const ApprovalTypes = {
  APISYSTEMADMINAPPROVAL: 'APISYSTEMADMINAPPROVAL',
  LISTINGTYPEBUSINESSUNITAPPROVAL: 'LISTINGTYPEBUSINESSUNITAPPROVAL',
  APIOWNERAPPROVAL: 'APIOWNERAPPROVAL',
  RISKAPPROVAL: 'RISKAPPROVAL',
} as const;

export function isApiApprovalNotification(
  n: Notification | ApprovalNotification,
): n is ApprovalNotification<ApiApprovalDetails> {
  return n.approvalRequestTypeMnemonic === ApprovalTypes.APIOWNERAPPROVAL;
}

export type ApprovalStatus =
  | 'APPROVED'
  | 'REJECTED'
  | 'PENDINGAPPROVAL'
  | 'APPROVALPREEMPTED'
  | 'APPROVALREQUESTCANCELED';

export type ApprovalType =
  | 'APISYSTEMADMINAPPROVAL'
  | 'APIOWNERAPPROVAL'
  | 'LISTINGTYPEBUSINESSUNITAPPROVAL'
  | 'RISKAPPROVAL'
  | 'BOTPROMOTIONAPPROVAALTYPE'
  | 'APIBUDGETAPPROVAL';
