import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import * as API from '@/api/listings.api';
import { getListingTypes, ListingTypesResponse } from '@/api/publishing.api';
import { getAdminListingTypes } from '@/api/listing-types.api';
import { ListingType } from '@/types/listing-types.types';
import store from '@/store';
import { FullListing, SavedFullListing } from '@/types/listings.types';
import URLs from '@/api/service/urls';
import { ListingSectionTemplateTypes } from '@/constants';
import { processDetails } from '@/utils/listings';
import { ListingBusinessUnit, ListingKeyword } from '@/types/api.types';

interface PaginationMeta {
  meta: {
    total: number;
    resultsPerPage: number;
    pageNumber: number;
    pagesLeft: number;
  };
}

const PER_PAGE = 200;

export type ListingsPaginated = API.Listings.Listings & PaginationMeta;

export interface ListingCatalogState {
  types: ListingsPaginated[];
}

@Module({ name: 'catalog', store, dynamic: true, namespaced: true })
class ListingCatalogModule extends VuexModule implements ListingCatalogState {
  public types: ListingsPaginated[] = [];
  public searchResults: ListingsPaginated[] = [];
  public listingTypes: ListingTypesResponse[] = [];
  public currentListing: SavedFullListing = getEmptyListing();
  public environments: API.ListingEnvironment[] = [];
  public listingIconTypes: ListingType[] = [];
  public businessUnits: ListingBusinessUnit[] = [];
  public keywords: ListingKeyword[] = [];
  public listingDetails: any;
  public tryItSelectedParams = {
    listingId: -1,
    projectId: -1,
    projectEnvironmentId: -1,
    serviceId: -1,
  };

  public get noCurrentListing(): boolean {
    return this.currentListing.listingId === -1;
  }

  public get detailedListing(): FullListing {
    const type = this.listingTypes.find(
      lt => lt.listingTypeId === this.currentListing.listingTypeId,
    );
    const { details } = this.currentListing.extendedProperties;
    return {
      ...this.currentListing,
      extendedProperties: {
        ...this.currentListing.extendedProperties,
        details: type ? processDetails(details, type.listingTemplate) : [],
      },
    };
  }

  @Mutation
  public resetCurrentListing() {
    this.currentListing = getEmptyListing();
  }

  @Mutation
  public setInitialListings(types: ListingsPaginated[]): void {
    this.types = types;
  }

  @Mutation
  public setSearchResults(listings: ListingsPaginated[]): void {
    this.searchResults = listings;
  }

  @Mutation
  public setCurrentListing(listing: SavedFullListing): void {
    this.currentListing = listing;
  }

  @Mutation
  public setEnvironments(environments: API.ListingEnvironment[]): void {
    this.environments = environments;
  }

  @Mutation
  public setCatalogListingTypes(listingTypes: ListingTypesResponse[]): void {
    this.listingTypes = listingTypes;
  }

  @Mutation
  public setListingIconTypes(listingIconTypes: ListingType[]): void {
    this.listingIconTypes = listingIconTypes;
  }

  @Mutation
  public setListingDetails(listing: any): void {
    this.listingDetails = listing;
  }

  @Mutation
  public setBuAndKeywords(payload: { bu: ListingBusinessUnit[]; keywords: ListingKeyword[] }) {
    this.businessUnits = payload.bu;
    this.keywords = payload.keywords;
  }

  @Mutation
  public setTryItParam({
    listingId,
    params
  }: {
    listingId: number,
    params: {
      projectId?: any,
      projectEnvironmentId?: any,
      serviceId?: any,
    },
  }) {
    const currentListingId = this.tryItSelectedParams.listingId;
    if (currentListingId !== listingId) {
      this.tryItSelectedParams = {
        listingId: -1,
        projectId: -1,
        projectEnvironmentId: -1,
        serviceId: -1,
      };
    }
    this.tryItSelectedParams = {
      ...this.tryItSelectedParams,
      ...params,
      listingId,
    };
  }

  public get currentListingSwaggerPath(): string {
    const ds = this.currentListing.extendedProperties.documentationSections;
    if (Array.isArray(ds)) {
      const section = ds.find(
        (sec): boolean =>
          sec.sectionInfo.sectionTemplateType === ListingSectionTemplateTypes.OpenApi,
      );
      if (section && section.sectionInfo.sectionContentUrl) {
        return section.sectionInfo.sectionContentUrl;
      }
    }
    if (this.currentListing.listingId) {
      return URLs.ListingOpenApiSpec.replace(
        ':listingId',
        this.currentListing.listingId.toString(),
      );
    }
    return '';
  }

  @Action({ commit: 'setCatalogListingTypes' })
  public async getCatalogListingTypes(): Promise<ListingTypesResponse[]> {
    return (await getListingTypes()).data.filter(lt => lt.count > 0 && lt.listingTypeId > 0);
  }

  @Action({ commit: 'setListingIconTypes' })
  public async getListingIconTypes(): Promise<ListingType[]> {
    return (await getAdminListingTypes()).data;
  }

  @Action({ commit: 'setSearchResults' })
  public async search(filter: API.SearchFilter): Promise<ListingsPaginated[]> {
    const result = await API.searchListings({
      pageNumber: 1,
      resultsPerPage: PER_PAGE,
      filter,
    });
    const { count, types } = result.data.searchResults;
    this.setBuAndKeywords({ bu: result.data.businessUnits, keywords: result.data.keywords });
    return (types || []).map((listing): ListingsPaginated => {
      return {
        ...listing,
        meta: {
          total: count,
          resultsPerPage: PER_PAGE,
          pageNumber: 1,
          pagesLeft: Math.ceil((count - PER_PAGE) / PER_PAGE),
        },
      };
    });
  }
  
  @Action({ commit: 'setCurrentListing', rawError: true })
  public async getListingDetails(listingId: number): Promise<SavedFullListing> {
    const result = await API.getListingDetails(listingId);
    return result.data;
  }

  @Action({ commit: 'setCurrentListing' })
  public async getListingVersionDetails(listingVersionId: number): Promise<SavedFullListing> {
    const result = await API.getListingVersionDetails(listingVersionId);
    return result.data;
  }

  @Action({ commit: 'setEnvironments' })
  public async getListingEnvironments(listingId: number): Promise<API.ListingEnvironment[]> {
    return (await API.getListingAvailableEnvironments(listingId)).data;
  }
}

function getEmptyListing(): SavedFullListing {
  return {
    authenticationType: '',
    listingId: -1,
    hasImage: false,
    listingTypeId: 1,
    listingTypeName: '',
    listingName: '',
    listingDescription: '',
    statusName: '',
    statusId: -1,
    iconUrl: '',
    listingTeamName: '',
    listingTeamIcon: '',
    isFeaturedFlag: false,
    createTimestamp: '',
    updateTimestamp: '',
    publishTimestamp: '',
    owners: [
      {
        firstName: '',
        lastName: '',
        mudId: '',
        email: '',
      },
    ],
    extendedProperties: {
      image: '',
      keywords: [],
      details: [],
      githubRepo: null,
      documentationSections: [
        {
          sectionInfo: {
            sectionName: '',
            sectionTemplateType: '',
            sectionTemplateTypeId: 1,
            sectionContent: '',
          },
          meta: {
            canDelete: true,
            canRename: true,
            canEditContent: true,
            canLinkContent: true,
            canReorder: true,
          },
        },
      ],
    },
    publishedBy: {
      firstName: '',
      lastName: '',
      mudId: '',
      email: '',
    },
    versions: [],
    teams: [],
  };
}

export const ListingsModule = getModule(ListingCatalogModule);
