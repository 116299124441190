
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import NavigationMixin from '@/components/mixins/navigation.mixin';
import { UINavigationItem } from '@/types';

@Component
export default class NavigationList extends mixins(NavigationMixin) {
  @Prop(Boolean) noLine!: boolean;
  @Prop(Boolean) bold!: boolean;
  @Prop(Boolean) primary!: boolean;
  @Prop(Boolean) disabled!: boolean;

  count(n: number | undefined | null): string | number {
    if (!n) {
      return 0;
    }

    return n > 99 ? '99+' : n;
  }

  graphic(link: UINavigationItem) {
    return link.props?.graphic;
  }

  icon(link: UINavigationItem) {
    return link.props?.icon;
  }

  mergeClasses(link: UINavigationItem) {
    return { ...this.classes(link), noLine: this.noLine, bold: this.bold };
  }
}
