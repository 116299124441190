import Vue from 'vue';
import Vuex from 'vuex';
import { Route } from 'vue-router';
import VuexPersistence from 'vuex-persist';
import { ProjectsState } from '@/store/modules/projects.module';
import { ListingCatalogState } from '@/store/modules/listings.module';
import { ProjectDetailsState } from '@/store/modules/project-details.module';
import { UserState } from '@/store/modules/user.module';
import { DynamicRegistrationState } from '@/store/modules/dynamic-registration.module';

Vue.use(Vuex);

export interface RouteState extends Route {
  from: Route;
}

export interface RootState {
  user: UserState;
  catalog: ListingCatalogState;
  projects: ProjectsState;
  projectDetails: ProjectDetailsState;
  route: RouteState;
  dynamicRegForm: DynamicRegistrationState;
}

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ['catalog', 'enums', 'teams'],
  filter(mutation) {
    const mutations: Record<string, boolean | undefined> = {
      setCatalogListingTypes: true,
      setCatalogTypeHistory: true,
      setInitialListings: true,
      setEnumType: true,
      setHasEnumStatus: true,
      setTeamsRecord: true,
    };
    return mutations[mutation.type] === true;
  },
});

// Store is declared empty because vuex-module-decorators will register modules dynamically
export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [vuexLocal.plugin],
});
