import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';

export function getHelpFileTree(params: {
  owner: string;
  repo: string;
  tree_sha: string;
}): AxiosPromise<string[]> {
  return axios.get(URLs.HelpFileTree, { params });
}
