
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Dialog } from '@gsk-tech/gsk-dialog/gsk-dialog';

interface DialogEvent extends CustomEvent {
  detail: {
    action?: 'cancel' | 'accept' | 'close' | string;
  };
}

const DEFAULT_SCRIM_ACTION = 'cancel';
const DISABLE_SCRIM_ACTION = '';

@Component
export default class GDialog extends Vue {
  @Prop({ type: Boolean, default: true }) readonly open!: boolean;
  @Prop(Boolean) readonly centerContent!: boolean;
  @Prop(Boolean) readonly hideHeader!: boolean;
  @Prop(Boolean) readonly disableCancel!: boolean;
  @Prop(Boolean) readonly danger!: boolean;
  @Prop({ type: String, default: '320px' }) readonly minWidth!: string;
  @Prop({ type: String, default: 'calc(100vh - 5rem)' }) readonly maxWidth!: string;

  $refs!: {
    dialog: Dialog;
  };

  get styles() {
    return {
      '--gsk-dialog-max-width': this.maxWidth,
      '--gsk-dialog-min-width': this.minWidth,
    };
  }

  mounted() {
    this.$nextTick(() => {
      this.handleDialog(this.open);
      if (this.disableCancel) {
        this.setDisableCancel(DISABLE_SCRIM_ACTION);
      }
    });
  }

  @Watch('disableCancel')
  handleDisableCancel(disable: boolean) {
    this.setDisableCancel(disable ? DISABLE_SCRIM_ACTION : DEFAULT_SCRIM_ACTION);
  }

  async setDisableCancel(action: string) {
    if (this.dialogEl()) {
      await this.dialogEl().updateComplete;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const f = (this.dialogEl() as any).mdcFoundation;
      f.setScrimClickAction(action);
      f.setEscapeKeyAction(action);
    }
  }

  get dialogEventHandlers() {
    return {
      closed: (e: DialogEvent) => this.handleMdcEvent(false, e),
      opened: (e: DialogEvent) => this.handleMdcEvent(true, e),
    };
  }

  hasFooter() {
    return !!this.$slots.footer;
  }

  @Watch('open', { immediate: true })
  handleOpen(isOpen: boolean) {
    this.handleDialog(isOpen);
  }

  dialogEl(): Dialog {
    return this.$refs.dialog;
  }

  handleDialog(isOpen: boolean) {
    if (!this.dialogEl()) {
      return;
    }
    this.dialogEl().updateComplete.then(() => {
      if (isOpen) {
        this.dialogEl().open();
      } else {
        this.dialogEl().close();
      }
    });
  }

  handleMdcEvent(isOpen: boolean, e: DialogEvent) {
    if (!isOpen && e.detail?.action) {
      if (e.detail.action === 'accept') {
        this.$emit('accept');
      } else {
        this.$emit('cancel');
      }
    }
    if (e.detail?.action) {
      this.$emit('update:open', isOpen);
    } else if (e.type === 'opened' || e.type === 'closed') {
      this.$emit(e.type);
    }
  }
}
