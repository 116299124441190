
import Vue from 'vue';
import LiquorTree from 'liquor-tree';
import { HelpModule } from '@/store/modules/help.module';
import { TreeNode } from '@/types/util.types';
import { UINavigationItem } from '@/types';
import { RouteNames } from '@/constants';
import NavigationList from '@/components/NavigationList.vue';
import TopNavigation from '@/components/TopNavigation.vue';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

export default Vue.extend({
  name: 'HelpLayout',
  components: {
    LiquorTree,
    NavigationList,
    TopNavigation,
  },
  data() {
    return {
      mql: window.matchMedia('(min-width: 850px)'),
      drawerOpen: true,
      drawerType: '',
      filter: '',
      search: '',
      title: '',
    };
  },
  computed: {
    files() {
      return HelpModule.fileTree;
    },
    defaultLink() {
      return HelpModule.defaultFilePath;
    },
    navLinks(): UINavigationItem[] {
      return [
        {
          text: 'My Listings',
          route: { name: RouteNames.MyListings },
          key: 'l',
          props: {
            noripple: true,
          },
        },
        {
          text: 'Projects',
          route: { name: RouteNames.ProjectsList },
          key: 'p',
          props: {
            noripple: true,
          },
        },
        {
          text: 'Product Catalog',
          route: { name: RouteNames.Listings },
          key: 'c',
          props: {
            noripple: true,
          },
        },
      ];
    },
  },
  watch: {
    $route() {
      if (this.drawerType === 'modal') {
        this.drawerOpen = false;
      }
      this.selectLink(this.$route.path);
    },
  },
  beforeDestroy() {
    this.mql.removeListener(this.setDrawerType);
  },
  mounted() {
    this.setDrawerType({ matches: this.mql.matches });
    this.mql.addListener(this.setDrawerType);
    HelpModule.loadFileTree({
      owner: ProcessEnvsModule.processEnvs.helpRepoOrg,
      repo: ProcessEnvsModule.processEnvs.helpRepoName,
      tree_sha: ProcessEnvsModule.processEnvs.helpRepoBranch,
    });
  },
  methods: {
    selectLink(path: string | void) {
      let sel: any = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tree = this.$refs.tree as any;
      if (path === undefined) {
        path = HelpModule.defaultFilePath;
      }
      sel = tree.find({
        data: {
          path: decodeURIComponent(path),
        },
      });
      if (sel && sel.length) {
        sel.select();
        let p = sel[0].parent;
        while (p) {
          p.expand();
          p = p.parent;
        }
      }
    },
    updateTitle(text: string) {
      this.title = text;
    },
    onTreeMount(e: any) {
      const { path } = this.$route;
      this.selectLink(path.endsWith('.md') ? path : undefined);
    },
    onNodeSelect(node: TreeNode) {
      if (!(node.children && node.children.length)) {
        if (!this.$route.path.endsWith('.md')) {
          // to catch the root folder initial navigation
          this.$router.replace(node.data.path).catch(() => "don't care");
        } else {
          this.$router.push(node.data.path).catch(() => undefined);
        }
        this.title = node.text;
      }
    },
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    setDrawerType(e: { matches: boolean }) {
      this.drawerType = e.matches ? 'dismissible' : 'modal';
      this.drawerOpen = e.matches;
    },
  },
});
