import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import { VuexModule } from 'vuex-module-decorators';
import { FormField } from '@/components/form/form.types';
import { ListingTypeTemplate } from '@/types/listings.types';
import { FeatureFlagMnemonics, FeatureFlags } from '@/types/feature-flags.types';
import { AppEnums } from '@/types/enum.types';

/**
 * syncs up stored details in saved listings with the template:
 * strategy is to take the values from the saved fields and insert them
 * into a copy of the template fields
 */
export function processDetails(form: FormField[], template: ListingTypeTemplate): FormField[] {
  // avoid modifying / pointing to the base template fields
  const tFields = cloneDeep(template.publishing.details.form.fields);
  const formMap = keyBy(form, 'key');
  return tFields.map(tField => {
    const field = formMap[tField.key];
    if (field) {
      // in case someone changes the type of field but leaves the key the same
      if (field.type === tField.type) {
        tField.value = field.value;
      }
    }
    return tField;
  });
}

export function listingTypeFilter(context: VuexModule['context'], mnemonic: FeatureFlagMnemonics) {
  const featureFlags: FeatureFlags = context.rootGetters['featureFlags/featureFlags'];
  const enums: AppEnums = context.rootGetters['enums/enums'];

  return function (listing: { listingTypeId: number }) {
    if (featureFlags[mnemonic].featureFlagEnabled) {
      return true;
    }
    return listing.listingTypeId !== enums.listingType.CICD.id;
  };
}
