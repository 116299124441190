import { extend } from 'vee-validate';

function isUrl(value: string): boolean {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}

/**
 * this is a very broad check, and will allow things you
 * don't expect to be a valid url
 */
extend('url', value => {
  return isUrl(value);
});

extend('kongUrl', value => {
  if (isUrl(value)) {
    const u = new URL(value);
    if (u.hostname.includes('..')) {
      return 'The URL hostname cannot include consecutive periods';
    }

    if (u.hostname === 'api.gsk.com') {
      return 'The API Gateway cannot accept URLs that include "api.gsk.com"';
    }

    if (
      /^http(s)?:\/\/([a-z\d][\w\-.]+\.[a-z]+)(:\d+)?(\/[\w\-.:%]+)*$/.test(value)
    ) {
      return true;
    }

    return 'The API Gateway cannot accept this URL';
  }

  return 'Not a valid URL';
});
