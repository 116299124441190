import Vue, { PluginObject } from 'vue';
import * as logger from 'loglevel';

export { logger };

let level: logger.LogLevelDesc = logger.levels.DEBUG;
if (process.env.NODE_ENV === 'production') {
  level = logger.levels.ERROR;
}
if (new URLSearchParams(location.search).get('debug')) {
  level = logger.levels.DEBUG;
}

logger.setLevel(level);

declare module 'vue/types/vue' {
  interface Vue {
    $logger: logger.RootLogger;
    $log: VueLog;
  }
}

const levels = new Set(['trace', 'debug', 'warn', 'info', 'error']);
type LogLevel = 'trace' | 'debug' | 'warn' | 'info' | 'error';
interface VueLog {
  (this: Vue, level: LogLevel, ...msg: unknown[]): void;
  (this: Vue, ...msg: unknown[]): void;
}
function isLogLevel(level: unknown): level is LogLevel {
  if (typeof level === 'string') {
    if (levels.has(level)) {
      return true;
    }
  }

  return false;
}

const log: VueLog = function (this: Vue, level: unknown, ...msg: unknown[]): void {
  const { $options = {} } = this || {};
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { __file: file } = $options;
  const { name } = $options;
  const id = name || (file as string | undefined) || '';
  const d = new Date();
  const timestamp = `[${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}]`;
  if (isLogLevel(level)) {
    logger[level](timestamp, level.toUpperCase(), `${id}:`, ...msg);
  } else {
    logger.debug(timestamp, 'DEBUG', `${id}:`, level, ...msg);
  }
};

export const LoggerPlugin: PluginObject<void> = {
  install(Vue): void {
    Vue.prototype.$logger = logger;
    Vue.prototype.$log = log;
  },
};

/**
 * adding it to window for convenience, changing levels at runtime, etc
 */
declare global {
  interface Window {
    $logger: typeof logger;
  }
}

window.$logger = window.$logger || logger;
