import { css, customElement, html, LitElement } from 'lit-element';
import { TemplateResult } from 'lit-html';

interface Component {
  name: string;
  dependencies: string;
  link: string;
  script: string;
}

@customElement('docs-component-picker')
export default class ComponentPicker extends LitElement {
  protected components: Component[] = [
    {
      name: 'Typography',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components',
      script: '@gsk-tech/gsk-typography',
    },
    {
      name: 'Address picker',
      dependencies: 'Textfield, Select',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/address-picker',
      script: '@gsk-tech/gsk-address-picker',
    },
    {
      name: 'Alert',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/alert',
      script: '@gsk-tech/gsk-alert',
    },
    {
      name: 'Autocomplete',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/autocomplete',
      script: '@gsk-tech/gsk-autocomplete',
    },
    {
      name: 'Button',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/button',
      script: '@gsk-tech/gsk-button',
    },
    {
      name: 'Card',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/card',
      script: '@gsk-tech/gsk-card',
    },
    {
      name: 'Chatbot Popover',
      dependencies: 'Popover, Fab',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/chatbot-popover',
      script: '@gsk-tech/gsk-chatbot-popover',
    },
    {
      name: 'Chatbot',
      dependencies: 'Chip, Textfield, Icon Button',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/chatbot',
      script: '@gsk-tech/gsk-chatbot',
    },
    {
      name: 'Checkbox',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/checkbox',
      script: '@gsk-tech/gsk-checkbox',
    },
    {
      name: 'Chip Set',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/chip-set',
      script: '@gsk-tech/gsk-chip-set',
    },
    {
      name: 'Chips',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/chips',
      script: '@gsk-tech/gsk-chip',
    },
    {
      name: 'Circular Progress',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/circular-progress',
      script: '@gsk-tech/gsk-circular-progress',
    },
    {
      name: 'Dialog',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/dialog',
      script: '@gsk-tech/gsk-dialog',
    },
    {
      name: 'Drawer',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/drawer',
      script: '@gsk-tech/gsk-drawer',
    },
    {
      name: 'Fab',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/fab',
      script: '@gsk-tech/gsk-fab',
    },
    {
      name: 'File upload',
      dependencies: 'Button, Icon Button',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/file-upload',
      script: '@gsk-tech/gsk-file-upload',
    },
    {
      name: 'Form',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/form',
      script: '@gsk-tech/gsk-form',
    },
    {
      name: 'Formfield',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/formfield',
      script: '@gsk-tech/gsk-formfield',
    },
    {
      name: 'Icon Button',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/icon-button',
      script: '@gsk-tech/gsk-icon-button',
    },
    {
      name: 'Icon',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/icon',
      script: '@gsk-tech/gsk-icon',
    },
    {
      name: 'Image list',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/image-list',
      script: '@gsk-tech/gsk-image-list',
    },
    {
      name: 'Image',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/image',
      script: '@gsk-tech/gsk-image',
    },
    {
      name: 'Input Chips',
      dependencies: 'Chip Set',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/input-chips',
      script: '@gsk-tech/gsk-input-chips',
    },
    {
      name: 'Linear Progress',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/linear-progress',
      script: '@gsk-tech/gsk-linear-progress',
    },
    {
      name: 'List',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/list',
      script: '@gsk-tech/gsk-list',
    },
    {
      name: 'Menu',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/menu',
      script: '@gsk-tech/gsk-menu',
    },
    {
      name: 'People Picker',
      dependencies: 'List Item',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/people-picker',
      script: '@gsk-tech/gsk-people-picker',
    },
    {
      name: 'Popover',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/popover',
      script: '@gsk-tech/gsk-popover',
    },
    {
      name: 'Radio',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/radio',
      script: '@gsk-tech/gsk-radio',
    },
    {
      name: 'Ripple',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/ripple',
      script: '@gsk-tech/gsk-ripple',
    },
    {
      name: 'Select',
      dependencies: 'Tooltip, Select, List Item, Chip Set',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/select',
      script: '@gsk-tech/gsk-select',
    },
    {
      name: 'Slider',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/slider',
      script: '@gsk-tech/gsk-slider',
    },
    {
      name: 'Snackbar',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/snackbar',
      script: '@gsk-tech/gsk-snackbar',
    },
    {
      name: 'Switch',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/switch',
      script: '@gsk-tech/gsk-switch',
    },
    {
      name: 'Tab Bar',
      dependencies: 'Tab Indicator, Tab Scroller',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/tab-bar',
      script: '@gsk-tech/gsk-tab-bar',
    },
    {
      name: 'Table',
      dependencies: 'Tab Indicator, Tab Scroller',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/table',
      script: '@gsk-tech/gsk-table',
    },
    {
      name: 'Textfield',
      dependencies: 'Tooltip, Icon',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/textfield',
      script: '@gsk-tech/gsk-textfield',
    },
    {
      name: 'Tooltip',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/tooltip',
      script: '@gsk-tech/gsk-tooltip',
    },
    {
      name: 'Top App Bar',
      dependencies: 'None',
      link:
        'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/top-app-bar',
      script: '@gsk-tech/gsk-top-app-bar',
    },
    {
      name: 'Video',
      dependencies: 'None',
      link: 'https://github.com/gsk-tech/design-system-web-components/tree/develop/packages/video',
      script: '@gsk-tech/gsk-video',
    },
  ];

  protected scripts = ['npm install --save'];

  public static styles = css`
    .picker-wrapper {
      display: inline-flex;
      width: 699px;
      height: 378px;
    }
    .section-title {
      font-weight: bold;
      margin-bottom: 19px;
    }
    .component-scroll {
      height: 300px;
      overflow-y: scroll;
    }
  `;

  protected async addScript(component: Component) {
    if (this.scripts.includes(component.script)) {
      this.scripts = this.scripts.filter(script => {
        return script !== component.script;
      });
    } else {
      this.scripts.push(component.script);
    }
    await this.requestUpdate();
  }

  protected render(): TemplateResult {
    return html`
      <div class="picker-wrapper">
        <div style="width: 393px">
          <div class="section-title">Components</div>
          <div class="component-scroll">
            <gsk-list>
              ${this.components.map(comp => {
                return html`
                  <gsk-list-item>
                    <gsk-formfield slot="graphic">
                      <gsk-checkbox @click="${() => this.addScript(comp)}"></gsk-checkbox>
                    </gsk-formfield>
                    <span>${comp.name}</span>
                    <a slot="meta" target="_blank" href="${comp.link}">
                      <gsk-icon-button icon="link_external" primary mini></gsk-icon-button>
                    </a>
                  </gsk-list-item>
                `;
              })}
            </gsk-list>
          </div>
        </div>
        <div>
          <div class="section-title">Your Build</div>
          <docs-copy-code>
            <div slot="code" style="height: 279px; width: 191px; margin-left: 15px;">
              <pre style="font-size: 14px">${this.scripts.join(' \\\n  ')}</pre>
            </div>
          </docs-copy-code>
        </div>
      </div>
    `;
  }
}
