import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { ProcessEnvsBase, ProcessEnvsResponse } from '@/types/process-envs.types';

export async function getAllProcessEnvs(): Promise<ProcessEnvsBase> {
  const enums = await axios.get<ProcessEnvsResponse>(URLs.ProcessEnvs, {});
  const { data } = enums;
  return {
    ...data,
    apiGatewayURLMapping: JSON.parse(data.apiGatewayURLMapping),
    federationURL: JSON.parse(data.federationURL),
  };
}
