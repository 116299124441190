
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Button } from '@gsk-tech/gsk-button/gsk-button';

@Component
export default class GButton extends Vue {
  @Prop(Boolean) readonly danger!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop({ type: String, default: 'flat' })
  readonly type!: 'flat' | 'text' | 'outlined';

  get props(): Partial<Button> {
    if (this.type === 'flat') {
      return {
        unelevated: true,
        filled: false,
      };
    } else if (this.type === 'outlined') {
      return {
        outlined: true,
        unelevated: false,
        filled: true,
      };
    }
    return {};
  }
}
