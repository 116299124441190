import {
  css,
  unsafeCSS,
  customElement,
  html,
  LitElement,
  property,
  PropertyValues,
} from 'lit-element';
import unescape from 'lodash/unescape';
import { TemplateResult } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
// eslint-disable-next-line
// @ts-ignore // ts doesn't like this import but webpack does!
import prismCss from '!!raw-loader!prismjs/themes/prism.css';


type pf = (v: string, highlight?: boolean) => string;
let prettier: pf = (v: string) => v;
const loading = Promise.all([
  import('prettier/standalone'),
  import('prettier/parser-html'),
  import('prismjs'),
] as const).then(([p, parser, prism]) => {
  prettier = (v: string, highlight = false) =>
    highlight
      ? prism.highlight(
          p.format(v, { parser: 'html', plugins: [parser] }),
          prism.languages.markup,
          'markup',
        )
      : p.format(v, { parser: 'html', plugins: [parser] });
});

@customElement('gsk-code-sandbox')
export default class TokenDownload extends LitElement {
  public static styles = css`
    ${unsafeCSS(prismCss)}
    .slot {
      display: none;
    }
    iframe {
      width: 100%;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-bottom: -7px;
      padding-bottom: 0;
    }
    pre {
      margin: 0;
      overflow-x: auto;
      padding-bottom: 1rem;
    }
    .code {
      background: var(--theme-lighter);
      padding: 1rem;
      padding-right: 2rem;
      padding-bottom: 0;
      position: relative;
      font-size: 14px;
    }
    .hide {
      display: none;
    }
    .copy {
      position: absolute;
      top: 8px;
      right: 8px;
    }
    .wrap {
      border: 1px solid var(--theme-lighter);
      border-radius: 4px;
    }
  `;

  @property({ type: String })
  public code = '';

  @property({ type: String })
  protected copyIcon = 'copy';

  @property({ type: String })
  public background = 'transparent';

  @property({ type: Boolean })
  public hideCode = false;

  @property({ type: String })
  public alignItems = 'center';

  @property({ type: String })
  public justifyContent = 'center';

  @property({ type: String })
  public padding = '1rem';

  @property({ type: String })
  public height = 'auto';

  public get unescapedCode() {
    return prettier(unescape(this.code), false);
  }

  public get higlightedCode() {
    return prettier(unescape(this.code), true);
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    if (!this.code && !this.slotCode) {
      const n = this.nextElementSibling || this.parentElement?.nextElementSibling;
      if (n?.tagName === 'PRE') {
        const pre = n as HTMLPreElement;
        const c = n.firstElementChild;
        if (c?.tagName === 'CODE') {
          // assuming that there is only escaped code in here
          // since it's in a readme
          this.code = c.innerHTML;
          pre.style.display = 'none';
        }
      }
    } else if (!this.code && this.slotCode) {
      this.code = this.slotCode;
    }
    loading.then(() => this.requestUpdate());
  }

  public copy() {
    navigator.clipboard
      .writeText(this.unescapedCode)
      .then(() => {
        this.copyIcon = 'check';
        setTimeout(() => {
          this.copyIcon = 'copy';
        }, 800);
      })
      .catch(() => {
        this.copyIcon = 'close';
        setTimeout(() => {
          this.copyIcon = 'copy';
        }, 1600);
      });
  }

  protected get slotCode() {
    return this.innerHTML.trim();
  }

  public get iframeSrc() {
    return 'data:text/html;base64,' + btoa(this.wrappedCode);
  }

  public get wrappedCode(): string {
    return `<!DOCTYPE html>
<html lang="en">
 <head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width,initial-scale=1">
  <title>Code Example</title>
  <script src="https://gsk-design-system.azureedge.net/webcomponents/all-package/dist/1.9/gsk-all-es5.js" defer></script>
  <link rel="stylesheet" href="https://gsk-design-system.azureedge.net/styles/fonts/dist/2.0/icons.css" />
  <style>
    body {
      box-sizing: border-box;
      margin: 0;
      padding: ${this.padding};
      display: flex;
      width: 100vw;
      height: 100vh;
      align-items: ${this.alignItems};
      justify-content: ${this.justifyContent};
      background: ${this.background};
    }

    body > * {
      margin: 6px;
    }
  </style>
 </head>
 <body>
 ${this.unescapedCode}
 </body>
</html>
    `;
  }

  protected render(): TemplateResult {
    const codeClasses = {
      code: true,
      hide: this.hideCode,
    };
    const iframeStyle = {
      height: this.height,
    };
    return html`
      <div class="wrap">
        <div class="frame">
          <iframe style="${styleMap(iframeStyle)}" src="${this.iframeSrc}" frameborder="0"></iframe>
        </div>
        <div class="${classMap(codeClasses)}">
          <gsk-icon-button
            mini
            icon="${this.copyIcon}"
            class="copy"
            @click="${this.copy}"
          ></gsk-icon-button>
          <pre><code>${unsafeHTML(this.higlightedCode)}</code></pre>
        </div>
        <div class="slot"><slot></slot></div>
      </div>
    `;
  }
}
