import { AxiosPromise } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import {
  GetRegistrationVersionsResponseBody,
  GithubRepo,
  MyListing,
} from '@/types/publishing.types';
import * as Listings from '@/types/listings.types';
import { ProjectTeam } from '@/types/teams.types';

export function getMyListings(): AxiosPromise<{ count: number; listings: MyListing[] }> {
  return axios.get(URLs.MyListings);
}

export interface ListingTypesResponse {
  count: number;
  defaultIcon: string | null;
  listingTemplate: Listings.ListingTypeTemplate;
  listingTypeDescription: string;
  listingTypeId: number;
  listingTypeName: string;
  sequenceNumber: number;
  category: Listings.ListingTypeCategory[];
  mnemonic: string;
  updateTimestamp: string;
  businessUnitsRequiringApprovals: number[];
}

export function getListingTypes(): AxiosPromise<ListingTypesResponse[]> {
  return axios.get(URLs.GetListingTypes);
}

export function getAllListings(): AxiosPromise<{ count: number; listings: MyListing[] }> {
  return axios.post(URLs.AllListings);
}

export function addSelfAsOwner(listingId: number, setAsOwner: boolean): void {
  axios.post(URLs.AddSelfAsOwner, {}, { params: { listingId, setAsOwner } });
}

export function unpublishListing(listingId: number): void {
  axios.post(URLs.UnpublishListing, {}, { params: { listingId } });
}

export function unpublishListingVersion(listingVersionId: number): void {
  axios.post(URLs.UnpublishListingVersion, {}, { params: { listingVersionId } });
}

export function setListingAsFeatured(listingId: number, setAsFeatured: boolean): void {
  axios.post(URLs.SetListingAsFeatured, {}, { params: { listingId, setAsFeatured } });
}

export function getAdminSettings(
  listingId: number,
): AxiosPromise<{ isCurrentOwner: boolean; isFeaturedFlag: boolean }> {
  return axios.get(URLs.GetAdminSettings, { params: { listingId } });
}

export function updateListingPermissions(
  listingUsers: Listings.ListingUser[],
  draftListingId: string,
  teams: ProjectTeam[],
): AxiosPromise<boolean> {
  const payload = { listingUsers, teams };
  return axios.put(URLs.UpdateListingPermissions, payload, {
    params: {
      draftListingId,
    },
  });
}

export function getRegistrationVersions(
  registrationId: number,
): AxiosPromise<GetRegistrationVersionsResponseBody> {
  return axios.get(URLs.GetRegistrationVersions, { params: { registrationId } });
}

// NON-VERSIONED PUB ENDPOINTS

export function getDraftListing(
  draftListingId: number | string,
): AxiosPromise<Listings.SavedListingModule> {
  return axios.get(URLs.GetDraftListing, { params: { draftListingId } });
}

export function deleteDraftListing(draftListingId: number): AxiosPromise<boolean> {
  return axios.delete(URLs.DeleteDraftListing, { params: { draftListingId } });
}

export function discardDraftListing(draftListingId: number): AxiosPromise<boolean> {
  return axios.post(URLs.DiscardDraftListing, {}, { params: { draftListingId } });
}

export function updateDraftListing(
  draft: Listings.DraftListingModulePayload,
  draftListingId: string,
  publishMessage: string,
): AxiosPromise<boolean> {
  return axios.put(URLs.UpdateDraftListing, draft, {
    params: {
      draftListingId,
      publishMessage,
    },
  });
}

export interface CreateDraftVersionableResponse {
  draftListingId: string;
  draftListingVersionId: string;
}

export function createDraftListing(
  draftListing: Listings.DraftListingModulePayload,
): AxiosPromise<number | CreateDraftVersionableResponse> {
  return axios.post(URLs.CreateDraftListing, draftListing);
}

export function publishDraftListing(
  draftListing: Listings.DraftListingModulePayload,
  draftListingId: string | number,
): AxiosPromise<{ draftlistingId: string; approvalsRequired: boolean }> {
  return axios.post(URLs.PublishDraftListing, draftListing, {
    params: {
      draftListingId,
    },
  });
}

export function publishNewDraftListing(
  draftListing: Listings.DraftListingModulePayload,
): AxiosPromise<{
  draftListingId: string;
  draftListingVersionId: string;
  approvalsRequired: boolean;
}> {
  return axios.post(URLs.PublishNewDraftListing, draftListing);
}

// VERSIONED PUB ENDPOINTS

export function getDraftListingVersion(
  draftListingVersionId: number | string,
): AxiosPromise<Listings.DraftListingVersion> {
  return axios.get(URLs.GetDraftListingVersion, { params: { draftListingVersionId } });
}

export function createDraftListingVersion(
  draftListing: Listings.DraftListingModulePayload,
  draftListingId: string | number,
): AxiosPromise<number> {
  return axios.post(URLs.CreateDraftListingVersion, draftListing, {
    params: {
      draftListingId,
    },
  });
}

export function updateDraftListingVersion(
  draft: Listings.DraftListingModulePayload,
  draftListingVersionId: string | number,
): AxiosPromise<boolean> {
  return axios.put(URLs.UpdateDraftListingVersion, draft, {
    params: {
      draftListingVersionId,
    },
  });
}

export function publishDraftListingVersion(
  draftListing: Listings.DraftListingModulePayload,
  draftListingVersionId: string | number,
): AxiosPromise<{
  draftListingId: string;
  draftListingVersionId: string;
  approvalsRequired: boolean;
}> {
  return axios.post(URLs.PublishDraftListingVersion, draftListing, {
    params: {
      draftListingVersionId,
    },
  });
}

export function publishNewDraftListingVersion(
  draftListing: Listings.DraftListingModulePayload,
  draftListingId: string | number,
): AxiosPromise<{
  draftListingId: string;
  draftListingVersionId: string;
  approvalsRequired: boolean;
}> {
  return axios.post(URLs.PublishNewDraftListingVersion, draftListing, {
    params: {
      draftListingId,
    },
  });
}

export function deleteDraftListingVersion(draftListingVersionId: number): AxiosPromise<boolean> {
  return axios.delete(URLs.DeleteDraftListingVersion, { params: { draftListingVersionId } });
}

export function discardDraftListingVersion(draftListingVersionId: number): AxiosPromise<boolean> {
  return axios.post(URLs.DiscardDraftListingVersion, {}, { params: { draftListingVersionId } });
}

export function getUserGithubRepos(mudId: string): AxiosPromise<GithubRepo[]> {
  return axios.get(URLs.GetUserGithubRepos, {
    params: {
      mudId,
    },
  });
}
