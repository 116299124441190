import { AxiosPromise } from 'axios';
import axios, { getData } from '@/api/service';
import URLs from '@/api/service/urls';
import {
  CreateFeatureFlagBody,
  FeatureFlag,
  UpdateFeatureFlagBody,
} from '@/types/feature-flags.types';

export function getFeatureFlags(): Promise<FeatureFlag[]> {
  return axios.get(URLs.FeatureFlags, {}).then(getData);
}
export function createFeatureFlag(featureFlag: CreateFeatureFlagBody): AxiosPromise<FeatureFlag> {
  return axios.post(URLs.FeatureFlags, featureFlag, {});
}

export function updateFeatureFlag(payload: UpdateFeatureFlagBody): AxiosPromise<FeatureFlag> {
  return axios.put(URLs.FeatureFlags, payload, {});
}
