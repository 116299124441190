
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import IdleVue from 'idle-vue';
import { RouteNames , Events} from '@/constants';
import GlobalSnackbar from '@/components/GlobalSnackbar.vue';
import { UserModule } from '@/store/modules/user.module';
import {
  analyticsPageView,
  analyticsUserLogin,
  extractAnalyticsFromRouteParams,
} from '@/analytics';
import { EnumsModule } from '@/store/modules/enums.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ListingsModule } from './store/modules/listings.module';
import IdleDialog from '@/components/dialogs/IdleDialog.vue';
import store from './store';
import { version as vers } from '../package.json';
import JsonViewer from 'vue-json-viewer';
import RootPopover from '@/components/gsk-components/popover/RootPopover.vue';
import sanitizeHtmlCustom from '@/directives/sanitize-html';

@Component({
  components: {
    GlobalSnackbar,
    RootPopover,
    IdleDialog,
  },
  metaInfo() {
    return {
      titleTemplate: 'Developer Portal%s',
    };
  },
})
export default class App extends Vue {
  ready = false;
  public version = vers;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private destroyBeforeEach = () => {};
  private lastPageViewTime = Date.now();
  private lastPageRoute = '';

  get isIdle(): boolean {
    return (
      window.location.pathname !== `/${RouteNames.AuthCallback}` &&
      this.$store.state?.idleVue?.isIdle
    );
  }

  beforeCreate(): void {
    if (window.location.pathname === `/${RouteNames.AuthCallback}`) {
      return;
    }

    UserModule.getUserInfo().then(() => {
      if (UserModule.user.mudId) {
        analyticsUserLogin();
      }
    });
    ProcessEnvsModule.getAllProcessEnvs();
    EnumsModule.getAllEnumTypes();
  }

  async created(): Promise<void> {
    if (window.location.pathname !== `/${RouteNames.AuthCallback}`) {
      await Promise.all([
        UserModule.userPromise,
        ProcessEnvsModule.updateComplete,
        EnumsModule.updateComplete,
        FeatureFlagsModule.updateComplete,
        ListingsModule.getListingIconTypes(),
      ]);
    }

    this.ready = true;

    const eventsHub = new Vue();

    // Register IdleVue in created hook to ensure userInfo has loaded
    Vue.use(IdleVue, {
      eventEmitter: eventsHub,
      store,
      startAtIdle: false,
      idleTime: (12 * 60 * 60 * 1000) - (1000 * 60 * 10), // Idletime is now 12 hours( 1sec * 60 = 1min * 60 = 1hour * 12 = 12 hours) - 2 minutes(2 mins for inactivity check)
      events: ['keydown', 'mousedown', 'touchstart'],
    });

    Vue.use(JsonViewer);
    Vue.use(sanitizeHtmlCustom);

    this.destroyBeforeEach = this.$router.beforeEach((to, from, next) => {
      if (from.meta?.confirmExit) {
        const answer = window.confirm(from.meta?.confirmExit);
        if (!answer) {
          return next(false);
        }
      }
      // analytics registers first page view itself
      if (getRouteId(to) === this.lastPageRoute) {
        return next();
      }
      this.$root.$emit(Events.ClosePopover, { force: true });
      const data = extractAnalyticsFromRouteParams(to.params);
      this.lastPageRoute = getRouteId(to);
      const now = Date.now();
      const timeSpent = Math.round((now - this.lastPageViewTime) / 1000);
      this.lastPageViewTime = now;
      analyticsPageView({ timeSpent, ...data }, { to, from });
      next();
    }) as () => void;
  }

  beforeDestroy(): void {
    if (window.location.pathname === `/${RouteNames.AuthCallback}`) {
      return;
    }
    this.destroyBeforeEach();
  }
}

function getRouteId(r: Route) {
  return `${r.name}::${JSON.stringify(r.params)}:${JSON.stringify(r.query)}`;
}
