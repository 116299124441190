
import { Component, Vue } from 'vue-property-decorator';
import { PopoverBase } from '@gsk-tech/gsk-popover/gsk-popover-base';
import { Events } from '@/constants';

@Component({})
export default class RootPopover extends Vue {
  $refs!: {
    popover?: PopoverBase;
  };

  isOpen = false;
  anchor: HTMLElement | null = null;
  id = '';
  noPad = false;

  created() {
    this.$root.$on(
      Events.OpenPopover,
      (opts: { anchor: HTMLElement; noPad?: boolean; id: string }) => {
        this.anchor = opts.anchor;
        this.noPad = !!opts.noPad;
        this.isOpen = true;
        this.id = opts.id;
        this.updatePopover(true);
      },
    );
    this.$root.$on(
      Events.ClosePopover,
      (opts: { anchor?: HTMLElement; force?: boolean; id: string }) => {
        // if the anchor changed, something else is trying to open the popover so we don't close it
        if (opts.id === this.id || opts.force) {
          this.isOpen = false;
        }
      },
    );
  }

  updatePopover(isOpen: boolean) {
    setTimeout(() => {
      if (isOpen && this.anchor && this.$refs.popover) {
        if (this.anchor) {
          this.$refs.popover?.setAnchorElement(this.anchor);
          this.$refs.popover?.['setup']();
          this.$refs.popover?.popper.update();
        }
      } else if (!this.anchor) {
        this.$logger.warn('anchor missing');
      }
    });
  }
}
