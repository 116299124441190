
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Tooltip } from '@gsk-tech/gsk-tooltip/gsk-tooltip';
import { randId } from '@/utils/components';


@Component
export default class CopyCode extends Vue {
  @Prop({ type: String, required: true }) code!: string;
  @Prop({ type: String, default: 'Copied!' }) successMessage!: string;
  @Prop({ type: String, default: 'Copy failed!' }) failMessage!: string;
  @Prop(Boolean) overflow!: boolean;
  @Prop(Boolean) secret!: boolean;
  @Prop(Boolean) inlineView!: boolean;
  @Prop(String) styling!: string;
  @Prop({ type: String, default: 'copy' }) icon!: string;
  @Prop({ type: String, default: 'Copy' }) copyMessage!: string;

  private id = randId();
  private hidden: boolean = this.secret;
  private text: string = this.copyMessage;;

  setDefaultText() {
    this.text = this.copyMessage;;
  }

  get isHidden() {
    return this.secret ? this.hidden : false;
  }

  toggle() {
    this.hidden = !this.hidden;
    if (this.isHidden) {
      this.$emit('hide');
    } else {
      this.$emit('show');
    }
  }

  showTooltip() {
    const tooltip = this.$refs.tooltip as Tooltip;
    tooltip.show();
  }

  @Watch('secret')
  onSecretChange() {
    this.hidden = this.secret;
  }

  get dots() {
    return this.isHidden ? '******************' : this.code;
  }

  copySuccess() {
    this.text = this.successMessage;
    setTimeout(() => {
      this.showTooltip();
    });
  }

  copyError() {
    this.text = this.failMessage;
    setTimeout(() => {
      this.showTooltip();
    });
  }
}
