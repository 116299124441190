import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IAppUser } from '@/types/users.types';
import * as AuthAPI from '@/api/auth.api';

import store from '@/store';

export interface UserState {
  user: IAppUser;
}

@Module({ name: 'user', store, dynamic: true })
class User extends VuexModule implements UserState {
  public defaultUserInfo: IAppUser = {
    mudId: '',
    userId: -1,
    roles: [],
    groups: [],
    fullName: '',
    lastName: '',
    firstName: '',
    email: '',
    refreshToken: '',
    accessToken: '',
    accessTokenExpiration: '',
    sessionTimeMs: -1,
  };

  public user: IAppUser = this.defaultUserInfo;

  public userPromise: Promise<IAppUser> = Promise.resolve(this.user);

  @Mutation
  private setUser(user: IAppUser): void {
    this.user = user;
  }

  @Mutation
  private setUserPromise(user: Promise<IAppUser>): void {
    this.userPromise = user;
  }

  @Mutation
  public setUserToDefault(defaultUser: IAppUser): void {
    this.user = defaultUser;
  }

  @Mutation
  public setDefaultUserPromise(defaultUser: IAppUser): void {
    this.userPromise = Promise.resolve(defaultUser);
  }

  @Action
  public async getUserInfo(): Promise<IAppUser> {
    const p = AuthAPI.getUser().then(r => r.data);
    this.setUserPromise(p);
    this.setUser(await p);
    return p;
  }

  @Action
  public async logoutUser(): Promise<void> {
    await AuthAPI.logoutUser();
    this.setUserToDefault(this.defaultUserInfo);
    this.setDefaultUserPromise(this.defaultUserInfo);
  }
}

export const UserModule = getModule(User);
