import Vue from 'vue';
import VueMeta from 'vue-meta';
import { directive as onClickaway } from 'vue-clickaway2';
import VueClipboard from 'vue-clipboard2';
import PortalVue from 'portal-vue';
import { LoggerPlugin } from './utils/logger';
import 'vue-easytable/libs/theme-default/index.css'; // import style
import VueEasytable from 'vue-easytable'; // import library
import '@gsk-tech/gsk-layout-grid/dist/gsk-layout-grid.css';
import './styles/main.scss';
import './features/om27/styles/ve-table.scss';
import './register-custom-elements';
import './form-validation/register-form-validation';
import './utils/progress';
import './utils/router-enhancements';
import './directives/index';
import store from './store';
import { Component } from 'vue-property-decorator';

declare global {
  interface Window {
    VuexStore: typeof store;
  }
}

declare global {
  interface ImportMeta {
    env: Record<string, string>;
  }
}

window.VuexStore = store;

Vue.config.productionTip = false;

// directives
Vue.directive('onClickaway', onClickaway);

// plugins
Vue.use(LoggerPlugin);
Vue.use(PortalVue);
Vue.use(VueClipboard);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueEasytable);

Component.registerHooks(['beforeRouteUpdate']);
