import Vue from 'vue';
import Router, { NavigationGuard, Route } from 'vue-router';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import FullScreenModalLayout from '@/layouts/FullScreenFormLayout.vue';
import { RouteNames } from '@/constants';
import DocumentationLayout from '@/layouts/DocumentationLayout.vue';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import { FeatureFlagMnemonics } from '@/types/feature-flags.types';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

import AuthCallback from './views/AuthCallback.vue';

if (window.location.pathname !== `/${RouteNames.AuthCallback}`) {
  FeatureFlagsModule.getFeatureFlags();
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  /* eslint-disable @typescript-eslint/explicit-function-return-type */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { selector: '#layout-main', x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: '/authcallback',
      name: RouteNames.AuthCallback,
      component: AuthCallback,
    },
    {
      path: '/admin',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.Admin,
          component: () => import('./views/admin/AdminDashboard.vue'),
        },
        {
          path: 'feature-flags',
          name: RouteNames.AdminFeatureFlags,
          component: () => import('./views/admin/FeatureFlags.vue'),
        },
        {
          path: 'listing-types',
          name: RouteNames.AdminListingTypes,
          component: () => import('./views/admin/ListingTypes.vue'),
        },
        {
          path: 'application-team-roles',
          name: RouteNames.AdminApplicationTeamRoles,
          component: () => import('./views/admin/ApplicationTeamRoles.vue'),
        },
        {
          path: 'listing-type-business-unit-approvers',
          name: RouteNames.AdminListingTypeBusinessUnitApprovers,
          component: () => import('./views/admin/ListingTypeApproversAdministration.vue'),
        },
        {
          path: 'service-project-movement',
          name: RouteNames.AdminServiceProjectMovement,
          component: () => import('./views/admin/ServiceProjectMovement.vue'),
        },
        {
          path: 'service-management',
          name: RouteNames.AdminServiceManagement,
          component: () => import('./views/admin/ServiceManagement.vue'),
        },
        {
          path: 'orchestrator-monitor',
          name: RouteNames.OrchestratorMonitor,
          component: () => import('./views/admin/OrchestratorMonitor.vue'),
        },
        {
          path: 'to-locking-statuses',
          name: RouteNames.AdminTOLockingStatuses,
          component: () => import('./views/admin/ToLockingStatuses.vue'),
        },
        {
          path: '/admin/kong-monitoring-panel',
          name: RouteNames.KongMonitoringPanel,
          component: () => import('./views/admin/KongMonitoringPanel.vue'),
        },
        {
          path: '/admin/cross-charging-reporting',
          name: RouteNames.CrossChargingReporting,
          component: () => import('./views/admin/CrossChargingReporting.vue'),
        },
      ],
    },
    {
      path: '/admin/registration-form-testing',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.AdminRegistrationFormTesting,
          component: () => import('./views/admin/RegistrationFormTesting.vue'),
        },
      ],
    },
    {
      path: '/notifications',
      component: DefaultLayout,
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.NOTIFICATIONS),
      children: [
        {
          path: '/',
          name: RouteNames.Notifications,
          component: () => import('./views/NotificationCenter.vue'),
          children: [
            {
              path: ':type',
              name: RouteNames.NotificationTable,
              component: () => import('./views/NotificationTable.vue'),
              props: true,
            },
          ],
        },
        {
          path: ':type/:id(\\d+)',
          name: RouteNames.NotificationDetails,
          component: () => import('./views/NotificationDetails.vue'),
        },
      ],
    },
    {
      path: '/projects/approvals/:approvalId',
      component: DefaultLayout,
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.REGISTRATION),
      children: [
        {
          path: '/',
          component: () => import('./views/Approval.vue'),
        },
      ],
    },
    {
      path: '/test-form',
      component: EmptyLayout,
      children: [
        {
          path: '/',
          component: () => import('./views/TestForm.vue'),
        },
      ],
    },
    {
      path: '/projects/new',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.NewProject,
          component: () => import('./views/ProjectTemplate.vue'),
        },
        {
          path: 'rpa/:type(project)/:projectId?',
          props: true,
          beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.BOTPROMOTION),
          name: RouteNames.NewRpaProject,
          component: () => import('./views/NewProjectRpa.vue'),
        },
        {
          path: 'rpa/:type(bot)/:botSystem/:botRegistrationId/:projectId?',
          props: true,
          beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.BOTPROMOTION),
          name: RouteNames.NewRpaProjectBot,
          component: () => import('./views/NewProjectRpa.vue'),
        },
        {
          path: ':type(api|cicd)',
          name: RouteNames.NewProjectForm,
          component: () => import('./views/ProjectNew.vue'),
        },
      ],
    },
    {
      path: '/project',
      component: FullScreenModalLayout,
      children: [
        {
          path: ':projectId/env/:envId/promote/:botId/:botDeploymentId',
          beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.BOTPROMOTION),
          name: RouteNames.PromoteRpaBot,
          component: () => import('./views/projects/PromoteRpaBot.vue'),
          props: true,
        },
        {
          path: ':projectId/env/:envId/bot/:botRegistrationId/edit',
          beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.BOTPROMOTION),
          props: true,
          name: RouteNames.EditRpaBot,
          component: () => import('./views/projects/EditRpaBot.vue'),
        },
      ],
    },
    {
      path: '/listings/:listingId/connect',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ListingConnect,
          component: () => import('./views/ProjectConnect.vue'),
        },
        {
          path: 'cicd',
          name: RouteNames.ListingConnectCicd,
          component: () => import('./views/ProjectConnectCicd.vue'),
        },
      ],
    },
    {
      path: '/projects/:id/env/:env/promote',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ProjectPromote,
          component: () => import('./views/PromoteProject.vue'),
        },
      ],
    },
    {
      path: '/projects/:id/env/:env/promotenew',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ProjectPromoteNew,
          component: () => import('./views/PromoteProjectNew.vue'),
        },
      ],
    },
    {
      path: '/projects/:id/settings',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ProjectSettings,
          component: () => import('./views/ProjectDetailsSettings.vue'),
        },
        {
          path: 'permissions',
          name: RouteNames.ProjectPermissions,
          component: () => import('./views/ProjectPermissionsSettings.vue'),
        },
      ],
    },
    {
      path: '/projects/:id/env/:env/registration',
      component: DefaultLayout,
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.REGISTRATION),
      children: [
        {
          path: '/',
          name: RouteNames.RegisterApi,
          component: () => import('./views/api-registration/ApiRegistration.vue'),
        },
        {
          path: 'options',
          name: RouteNames.RegisterApiOptions,
          component: () => import('./views/api-registration/ApiRegistrationOptions.vue'),
        },
        {
          path: 'newapi',
          name: RouteNames.RegisterNewApi,
          component: () => import('./views/api-registration/NewApiRegistration.vue'),
        },
        {
          path: 'newedit/:serviceId',
          name: RouteNames.RegisterApiNewEdit,
          component: () => import('./views/api-registration/NewApiRegistration.vue'),
        },
        {
          path: 'newversion/:serviceId',
          name: RouteNames.RegisterApiNewVersion,
          component: () => import('./views/api-registration/NewApiRegistration.vue'),
        },
        {
          path: 'version/:serviceId',
          name: RouteNames.RegisterApiVersion,
          component: () => import('./views/api-registration/ApiRegistration.vue'),
        },
        {
          path: 'edit/:serviceId',
          name: RouteNames.RegisterApiEdit,
          component: () => import('./views/api-registration/ApiRegistration.vue'),
        },
      ],
    },
    {
      path: '/projects/:projectId/env/:envId/connected-projects/:serviceId',
      name: RouteNames.ProjectConnected,
      props: true,
      component: () => import('./views/ProjectsConnected.vue'),
    },
    {
      path: '/projects',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ProjectsList,
          component: () => import('./views/ProjectsList.vue'),
        },
        {
          path: ':id',
          name: RouteNames.ProjectDetails,
          component: () => import('./views/ProjectDetails.vue'),
          children: [
            {
              name: RouteNames.ProjectRpa,
              path: 'env/:env/rpa',
              beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.BOTPROMOTION),
              props: true,
              component: () => import('./views/ProjectRpaDetails.vue'),
            },
            {
              name: RouteNames.ProjectEnvDetails,
              component: () => import('./views/ProjectEnv.vue'),
              path: 'env/:env/:section?',
              props: true,
            },
            {
              name: RouteNames.ProjectCiCd,
              path: 'cicd',
              component: () => import('./views/ProjectCicd.vue'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/opportunity-assessment',
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.OPPASS),
      name: RouteNames.LandingPage,
      component: () => import('./Rpa/RpaAssessmentTool/AssessmentLandingPage.vue'),
    },
    {
      path: '/opportunity-assessment/details',
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.OPPASS),
      name: RouteNames.OpportunityAssessment,
      component: () => import('./Rpa/RpaAssessmentTool/OpportunityAssessment.vue'),
    },
    {
      path: '/product-catalog',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          props: true,
          name: RouteNames.Listings,
          component: () => import('./views/ListingCatalog.vue'),
        },
        {
          path: ':type',
          props: true,
          name: RouteNames.ListingType,
          component: () => import('./views/ListingCatalog.vue'),
        },
      ],
    },
    {
      path: '/listings/:type?/:name?/:listingId',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.ListingDetails,
          props: true,
          component: () => import('./views/ListingDetail.vue'),
          children: [
            {
              name: RouteNames.ListingSection,
              component: () => import('./views/ListingDetailInfo.vue'),
              path: ':info?',
            },
          ],
        },
      ],
    },
    {
      path: '/my-listings',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.MyListings,
          component: () => import('./views/MyListings.vue'),
        },
        {
          path: 'publish/:listingId?/:listingVersionId?',
          name: RouteNames.PublishListing,
          props: true,
          component: () => import('./views/PublishNew.vue'),
        },
        {
          path: 'permissions',
          name: RouteNames.ListingPermissions,
          component: () => import('./components/Publish/Permissions.vue'),
        },
      ],
    },
    {
      path: '/my-teams',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.MyTeams,
          component: () => import('./views/MyTeams.vue'),
        },
      ],
    },
    {
      path: '/my-teams/new',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.NewTeam,
          component: () => import('./views/NewTeam.vue'),
        },
      ],
    },
    {
      path: '/my-teams/edit/:teamId',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.EditTeam,
          component: () => import('./views/EditTeam.vue'),
        },
      ],
    },
    {
      path: '/my-teams/details/:teamId',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.TeamDetails,
          component: () => import('./views/TeamDetails.vue'),
        },
      ],
    },
    {
      path: '/my-tokens',
      component: DefaultLayout,
      children: [
        {
          path: '/',
          name: RouteNames.MyTokens,
          component: () => import('./views/MyTokens.vue'),
        },
      ],
    },
    {
      path: '/my-tokens/generate',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.NewToken,
          component: () => import('./views/NewToken.vue'),
        },
      ],
    },
    {
      path: '/my-tokens/edit/:tokenId',
      component: FullScreenModalLayout,
      children: [
        {
          path: '/',
          name: RouteNames.EditToken,
          component: () => import('./views/EditToken.vue'),
        },
      ],
    },
    {
      path: `/${HELP_ROOT_PATH}`,
      component: DocumentationLayout,
      children: [
        {
          path: '*',
          name: 'documentation',
          component: () => import('./components/DocumentationViewer.vue'),
        },
      ],
    },
    {
      path: '/',
      redirect: '/product-catalog',
    },
    {
      path: '*',
      alias: '/404',
      props: true,
      name: RouteNames.NotFound,
      component: () => import('./views/NotFound.vue'),
    },
    {
      path: '/rpa-ops/open-ticket/:logicalBotId/:botId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsOpenTicket,
          component: () => import('./features/om27/views/OpenTicket.vue'),
        },
        {
          path: ':type(blocking|nonblocking|intermittent)',
          name: RouteNames.RpaOpsBlockingTicket,
          component: () => import('./features/om27/views/BlockingTicket.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOff,
          component: () => import('./features/om27/views/SignOff.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off-history/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOffHistory,
          component: () => import('./features/om27/views/SignOffHistory.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/sign-off-details/logical-bot/:logicalBotId/sign-off/:signOffId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSignOffDetails,
          component: () => import('./features/om27/views/SignOffDetails.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/reset-data',
      name: RouteNames.RpaOpsResetData,
      component: () => import('./features/om27/views/ResetData.vue'),
    },
    {
      path: '/rpa-ops/search',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsSearch,
          component: () => import('./features/om27/views/Search.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/edit-bot/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsEditBot,
          component: () => import('./features/om27/views/EditBot.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/edit-bot-history/:logicalBotId',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsEditBotHistory,
          component: () => import('./features/om27/views/EditBotHistory.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/unassigned-bots',
      component: () => import('./features/om27/layouts/SearchLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsUnassignedBots,
          component: () => import('./features/om27/views/UnassignedBots.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting',
      component: () => import('./features/om27/layouts/SearchLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReporting,
          component: () => import('./features/om27/views/ScheduledReportingList.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/create',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingCreate,
          component: () => import('./features/om27/views/ScheduledReportingCreate.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/edit/:reportName',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingEdit,
          component: () => import('./features/om27/views/ScheduledReportingCreate.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops/scheduled-reporting/view/:reportName',
      component: () => import('./layouts/FullScreenFormLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.RpaOpsScheduledReportingView,
          component: () => import('./features/om27/views/ScheduledReportingView.vue'),
        },
      ],
    },
    {
      path: '/rpa-ops',
      component: () => import('./features/om27/layouts/SearchLayout.vue'),
      beforeEnter: makeFeatureFlagRouteGuard(FeatureFlagMnemonics.RPAOPS),
      children: [
        {
          path: '/',
          name: RouteNames.Om27,
          component: () => import('./features/om27/views/main.vue'),
        },
      ],
    },
  ],
});

function makeFeatureFlagRouteGuard(
  flag: FeatureFlagMnemonics,
  allow?: (to: Route) => boolean,
): NavigationGuard {
  return async function featureFlagRouteGuard(to, from, next) {
    // disable by feature flag
    await FeatureFlagsModule.updateComplete;
    const enabled = FeatureFlagsModule.featureFlags[flag]?.featureFlagEnabled ?? false;

    // if feature enabled, always let through
    if (enabled) {
      return next();
      // if feature disabled and callback is given, leave it up to the callback
    } else if (allow && allow(to)) {
      return next();
    } else {
      return next({ name: RouteNames.NotFound });
    }
  };
}

export default router;
